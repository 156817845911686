import React from "react";
import { Paper } from "@mantine/core";
import myLogoName from "../assets/civicsync.svg"; // adjust path accordingly
import myLogo from "../assets/logo.svg"; // adjust path accordingly

interface CivicSyncLogoProps {
  size?: string;
  includeName?: boolean;
}

const CivicSyncLogo: React.FC<CivicSyncLogoProps> = ({ size, includeName }) => {
  {
    return (
      <img
        src={includeName ? myLogoName : myLogo}
        alt="CivicSync"
        style={{ height: size ? size : "30px" }}
      />
    );
  }
};
export default CivicSyncLogo;
