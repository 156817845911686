import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import React, { useEffect } from "react";
import { IconMessages } from "@tabler/icons-react";

import CivicSyncLogo from "./CivicSyncLogo";
import { Chip, Divider } from "@mantine/core";
import { ArrowRightIcon } from "@modulz/radix-icons";
import { useNavigate } from "react-router-dom";

import { AgentData } from "../redux/actions/chatActions";

interface SurveyPreviewProps {
  question: string;
  agents: Array<AgentData>;
  id?: string | boolean;
}

const ChatPreview: React.FC<SurveyPreviewProps> = ({
  question,
  agents,
  id,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);
  {
    return (
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        style={{ flexGrow: 1 }}
      >
        <Group position="apart" spacing="md">
          <Tooltip label="Focus Group" position="bottom" withArrow>
            <ThemeIcon color="yellow" variant="light">
              <IconMessages size={20} />
            </ThemeIcon>
          </Tooltip>
        </Group>
        <Divider my="sm" mx="-md" color="gray.1" />
        <Group spacing="xs">
          <Text align="left" weight={500}>
            {question}
          </Text>
        </Group>
        <Divider my="sm" mx="-md" color="gray.1" />
        <Group spacing="xs" mt="md" mb="xs">
          <Text align="left" size="sm" weight={700} color="blue">
            Members
          </Text>
        </Group>
        <Group spacing="xs">
          <Chip.Group>
            <Group position="left" spacing="xs">
              {agents.length === 0 ? (
                <Text size="xs">N/A</Text>
              ) : (
                agents.map((agent, index) => (
                  <Chip value={index} key={index}>
                    {agent.fullname}
                  </Chip>
                ))
              )}
            </Group>
          </Chip.Group>
        </Group>

        <Divider my="sm" mx="-md" color="gray.1" />
        <Group position="right">
          <Button
            variant="light"
            color="blue"
            mt="md"
            radius="md"
            onClick={() => navigate("/chat/" + id)}
          >
            {"Continue"}
          </Button>
        </Group>
      </Card>
    );
  }
};
export default ChatPreview;
