import axios from "axios";

import { AppThunk } from "../store";

import { BASE_API_URL } from "../../config";

import { AgentData } from "./chatActions";

export const FETCH_INSIGHTS = "FETCH_INSIGHTS";
export const FETCH_INSIGHTS_SUCCESS = "FETCH_INSIGHTS_SUCCESS";
export const FETCH_INSIGHTS_FAILURE = "FETCH_INSIGHTS_FAILURE";
export const EMPTY_INSIGHTS = "EMPTY_INSIGHTS";

// Define a type for the question data
export interface InsightData {
  [key: string]: any;
}

export interface InsightError {
  errorCode: number | null | undefined;
  agent: AgentData | null;
  error: string;
}

interface FetchInsightsAction {
  type: typeof FETCH_INSIGHTS;
}

interface FetchInsightSuccessAction {
  type: typeof FETCH_INSIGHTS_SUCCESS;
  payload: InsightData[]; // It'll return an array of questions
}

interface FetchInsightFailureAction {
  type: typeof FETCH_INSIGHTS_FAILURE;
  payload: InsightError;
}

interface EmptyInsightsAction {
  type: typeof EMPTY_INSIGHTS;
}

const insightUrl = `${BASE_API_URL}/activity/agent`;

export type InsightActionTypes =
  | FetchInsightsAction
  | FetchInsightSuccessAction
  | FetchInsightFailureAction
  | EmptyInsightsAction;

export const fetchInsights =
  (
    agent_id: string,
    page: number,
    searchTerm: string,
    emptyStore: boolean,
    token: string
  ): AppThunk =>
  async (dispatch) => {
    dispatch({ type: FETCH_INSIGHTS });
    if (emptyStore) {
      dispatch({ type: EMPTY_INSIGHTS, payload: [] });
    }

    try {
      const response = await axios.get(
        insightUrl + "/" + agent_id + "?page=" + page + "&term=" + searchTerm,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      console.log(response.data);
      if (response.data.code === 404) {
        const errorPayload = {
          errorCode: response.data.code,
          error: "No insights found",
          agent: response.data.agent,
        };
        dispatch({
          type: FETCH_INSIGHTS_FAILURE,
          payload: errorPayload,
        });
      } else {
        dispatch({
          type: FETCH_INSIGHTS_SUCCESS,
          payload: response.data.data,
        });
      }
      return Promise.resolve(); // Explicitly return a resolved Promise after successful action
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorPayload = {
          errorCode: error.response ? error.response.status : null,
          error: error.message,
          agent: null,
        };
        dispatch({
          type: FETCH_INSIGHTS_FAILURE,
          payload: errorPayload,
        });
        return Promise.reject(errorPayload); // Explicitly return a rejected Promise with error info
      } else {
        const unknownErrorPayload = {
          errorCode: null,
          error: "An unknown error occurred.",
          agent: null,
        };
        dispatch({
          type: FETCH_INSIGHTS_FAILURE,
          payload: unknownErrorPayload,
        });
        return Promise.reject(unknownErrorPayload); // Handle non-Axios errors
      }
    }
  };
