import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Stepper,
  Button,
  Group,
  Text,
  Alert,
  Loader,
  createStyles,
  rem,
  Paper,
  Box,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState, AppThunk } from "../redux/store"; // Adjust the path as necessary
import { BASE_API_URL } from "../config";
import {
  IconFileDownload,
  IconPlus,
  IconAlertCircle,
} from "@tabler/icons-react";

import {
  createIdentifier,
  ListPluginId,
  fetchUserVpnState,
} from "../redux/actions/authActions";
import { set } from "lodash";

const useStyles = createStyles((theme) => ({
  controls: {
    marginTop: theme.spacing.lg,
    display: "flex",
    justifyContent: "center",

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },
  control: {
    "&:not(:first-of-type)": {
      marginLeft: theme.spacing.md,
    },

    [theme.fn.smallerThan("xs")]: {
      height: rem(42),
      fontSize: theme.fontSizes.md,

      "&:not(:first-of-type)": {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },
}));

export function StepsCreateIdentifier() {
  const { classes } = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");
  const [identifiers, setIdentifiers] = useState<ListPluginId[]>([]);
  const navigate = useNavigate();
  const dispatch =
    useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const user = useSelector((state: RootState) => state.auth.userData);
  const loading = useSelector((state: RootState) => state.auth.loadingCertificate);
  const errorCode = useSelector((state: RootState) => state.auth.errorCode);
  const error = useSelector((state: RootState) => state.auth.error);
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken); // Update the state with the fetched token
      } catch (error) {
        console.error("Error fetching access token:", error);
        setToken(""); // Handle errors (e.g., by setting token to null)
      }
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (user?.plugin_id) {
      if (Array.isArray(user.plugin_id)) {
        // If it's an array, filter by active status
        const activeIdentifiers =
          user?.plugin_id
            .filter((id) => typeof id === "object" && id.active)
            .map((id) => ({ plugin_id: id.plugin_id, active: id.active })) ||
          []; // Default to an empty array if the result is falsy
        if (activeIdentifiers.length > 0) {
          setIdentifiers(activeIdentifiers);
          if (active === 0) {
            setActive(1);
          }
        } else {
          setIdentifiers([]);
          setActive(0);
        }
      }
    }
  }, [user]);

  const handleSaveCivicSyncIdentifier = () => {
    dispatch(createIdentifier(token));
  };

  const downloadCertificateOne = async (userId: string) => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}/agent/download-certificate/${userId}.ovpn`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob", // Important for file download
        }
      );

      // Create a link element to trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${userId}.ovpn`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const downloadCertificateTwo = async () => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}/agent/download-certificate/civicsync.cer`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob", // Important for file download
        }
      );

      // Create a link element to trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `civicsync.cer`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const rows = identifiers.map((identifier, index) => (
    <div className={classes.controls}>
      <Button
        className={classes.control}
        leftIcon={<IconFileDownload size="1rem" />}
        color="cyan"
        variant="light"
        onClick={() => downloadCertificateTwo()}
      >
        civicsync.cer
      </Button>
      <Button
        className={classes.control}
        leftIcon={<IconFileDownload size="1rem" />}
        color="cyan"
        variant="light"
        onClick={() => downloadCertificateOne(identifier.plugin_id)}
      >
        {identifier.plugin_id}.ovpn
      </Button>
    </div>
  ));

  const [active, setActive] = useState(0);

  return (
    <Paper shadow="xs" p="lg">
      <Stepper active={active} onStepClick={setActive} breakpoint="sm">
        <Stepper.Step
          label=""
          description="Identifier"
          disabled={identifiers.length > 0 ? true : false}
        >
          <>
            <Text size="md" color="dimmed">
              We first need to create an identifier, which will serve as your
              unique identifier. Please keep this private and do not share it
              with anyone else.
            </Text>

            <Alert
              mt={10}
              icon={<IconAlertCircle size="1rem" />}
              title=""
              color="yellow"
            >
              By creating an identifier, you accept CivicSync's{" "}
              <a href="https://civicsync.com/terms/" target="_blank">
                Terms of Use and Privacy Policy.
              </a>
              <Group position="left" mb="md" mt={10}>
                <Button
                  disabled={loading}
                  variant="light"
                  leftIcon={<IconPlus />}
                  onClick={() => handleSaveCivicSyncIdentifier()}
                >
                  Create Identifier
                </Button>
              </Group>
              {loading && (
                <div>
                  <Loader mt={10} />
                </div>
              )}
            </Alert>
          </>
        </Stepper.Step>
        <Stepper.Step
          label=""
          description="Certificates"
          disabled={identifiers.length === 0 ? true : false}
        >
          <Text size="md" color="dimmed" mb={20}>
            To activate the CivicSync VPN, you need to download and install the
            necessary certificates. The first file is <b>civicsync.cer</b>{" "}
            certificate that must be installed and trusted on your device,
            whether it's a desktop or mobile phone. Additionally, please install
            the OpenVPN Connect app on your device and import the <b>ovpn</b>{" "}
            file into it. For detailed instructions, you can read more about the
            installation process <a href="">here</a>.
          </Text>
          {rows}
          <Box style={{ display: "flex", justifyContent: "flex-end" }} mt={25}>
            <Button variant="subtle" onClick={() => setActive(2)}>
              Next step
            </Button>
          </Box>
        </Stepper.Step>
        <Stepper.Step
          label=""
          description="Validate"
          disabled={identifiers.length === 0 ? true : false}
        >
          {user && !user.vpn_active && (
            <Text size="md" color="dimmed">
              To validate your identifier and certificates, please turn on the
              VPN on the device where you completed the setup. Then, visit any
              website using your preferred browser.
            </Text>
          )}
          <Alert
            mt={10}
            icon={<IconAlertCircle size="1rem" />}
            title={
              user && user.vpn_active
                ? "Setup completed!"
                : "Setup not completed yet"
            }
            color={user && user.vpn_active ? "green" : "orange"}
          >
            {user && !user.vpn_active ? (
              <>
                Please follow the instructions and check again.
                <Group position="left" mb="md" mt={10}>
                  <Button
                    disabled={loading}
                    variant="light"
                    onClick={() => dispatch(fetchUserVpnState(token))}
                  >
                    Check setup
                  </Button>
                </Group>
                {loading && <Loader mt={10} />}
              </>
            ) : (
              <>
                Setup completed successfully. You are ready to start interacting
                with your digital replica.
                <Box
                  style={{ display: "flex", justifyContent: "flex-end" }}
                  mt={25}
                >
                  <Button variant="subtle" onClick={() => setActive(3)}>
                    Next step
                  </Button>
                </Box>
              </>
            )}
          </Alert>
        </Stepper.Step>
        <Stepper.Completed>
          <Text size="lg" color="dimmed" align="center">
            You're all set to start chatting with your digital replica. Create
            your chat now!
          </Text>
          <Box style={{ display: "flex", justifyContent: "center" }} mt={25}>
            <Button onClick={() => navigate("/chat/me")}>Start chatting</Button>
          </Box>
        </Stepper.Completed>
      </Stepper>

      {/* <Group position="center" mt="xl">
        <Button variant="default" onClick={prevStep}>
          Back
        </Button>
        <Button onClick={nextStep}>Next step</Button>
      </Group> */}
    </Paper>
  );
}
