import React, { useState } from "react";
import { Chip, Group, Modal, Button, Divider } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";

import "../styles/chat/Sidebar.css";

import { Accordion, Avatar, Text, ScrollArea } from "@mantine/core";

const Sidebar = (props: any) => {
  const navigate = useNavigate();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const { chatParticipents } = props;
  return (
    <ScrollArea pr={10} pl={10} style={{ height: "calc(100%)" }} type="scroll">
      <Accordion>
        {chatParticipents.map((person: any, index: any) => (
          <Accordion.Item key={index} value={String(index)}>
            <Accordion.Control>
              <div style={{ display: "flex", alignItems: "center" }}>
                {" "}
                {/* Flex container */}
                <Avatar
                  src={
                    "https://api.dicebear.com/8.x/lorelei-neutral/svg?backgroundColor=b6e3f4,c0aede,d1d4f9&seed=" +
                    person?.fullname
                  }
                  style={{ marginRight: "10px" }}
                />
                <Text
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "150px", // Adjust this value based on your layout needs
                  }}
                >
                  {person.fullname}
                </Text>
              </div>
            </Accordion.Control>
            <Accordion.Panel>
              <Chip.Group>
                <Group position="left" spacing="xs">
                  <Chip defaultChecked color="red" variant="light">
                    <span className="truncate">
                      {currentYear - person.year_of_birth} years old
                    </span>
                  </Chip>
                  <Chip defaultChecked color="green" variant="light">
                    <span className="truncate">{person.city_of_residence}</span>
                  </Chip>
                  <Chip defaultChecked color="orange" variant="light">
                    <span className="truncate">{person.education_level}</span>
                  </Chip>
                  <Chip defaultChecked color="pink" variant="light">
                    <span className="truncate">{person.household_income}</span>
                  </Chip>
                </Group>
              </Chip.Group>
              <p>
                <strong>Bio: </strong>
                {person.bio.slice(0, 400) + "..."}
              </p>
              <Button onClick={() => navigate("/insights/" + person.id)}>
                More info
              </Button>
              {/* Add more sections as needed */}
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
      <Divider my="sm" mx="-md" color="gray.1" />
      <Button variant="light" fullWidth>
        Add participents
      </Button>
      <Button
        mt={5}
        variant="light"
        color="yellow"
        fullWidth
        onClick={() => navigate("/chat/new-chat/")}
      >
        New chat
      </Button>
    </ScrollArea>
  );
};

export default Sidebar;
