import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { notifications } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState, AppThunk } from "../redux/store";
import { logout } from "../redux/actions/authActions";
import {
  fetchAgents,
  saveChat,
  resetChatSaved,
} from "../redux/actions/chatActions";
import { Avatar, Text, Group, Card, Chip, ActionIcon } from "@mantine/core";
import { IconPhoneCall, IconAt } from "@tabler/icons-react";
import classes from "./UserInfoIcons.module.css";
import { IconMessage } from "@tabler/icons-react";

export function UserInfoCard() {
  const dispatch =
    useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const chat = useSelector((state: RootState) => state.chat);
  const isChatSaved = useSelector((state: RootState) => state.chat.saved);
  const insightData = useSelector((state: RootState) => state.insight);
  const navigate = useNavigate();
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken); // Update the state with the fetched token
      } catch (error) {
        console.error("Error fetching access token:", error);
        setToken(""); // Handle errors (e.g., by setting token to null)
      }
    };
    fetchToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (chat.errorCode === 401) {
      dispatch(logout());
      navigate("/");
    }
    if (chat.errorCode) {
      notifications.show({
        title: "Error",
        message: chat.error,
        color: "red",
      });
    }
  }, [chat]);

  useEffect(() => {
    if (isChatSaved) {
      if (Array.isArray(chat.data) && chat.data.length > 0) {
        const id = chat.data[0].id;
        dispatch(resetChatSaved());
        navigate("/chat/" + id);
      } else {
        notifications.show({
          title: "Success",
          message: "Chat saved.",
          color: "green",
        });
      }
      console.log("Chat saved");
    }
  }, [isChatSaved]);

  const createChatHandler = () => {
    console.log("Create chat with these agents:", insightData?.agent?.id);
    const newChatData = {
      agent_id: [insightData?.agent?.id],
      messages: [],
    };

    dispatch(saveChat(newChatData, token));
    // Implement your logic for creating a chat here
  };

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Group>
        <Avatar
          src={
            "https://api.dicebear.com/8.x/lorelei-neutral/svg?backgroundColor=b6e3f4,c0aede,d1d4f9&seed=" +
            insightData?.agent?.fullname
          }
          size={94}
          radius="md"
        />
        <div>
          <Text fz="xs" tt="uppercase" fw={700} c="dimmed">
            {insightData?.agent?.bio}
          </Text>

          <Text fz="lg" fw={500} className={classes.name}>
            {insightData?.agent?.fullname}
          </Text>

          <Text fz="xs" c="dimmed">
            {insightData?.agent?.additional_info}
          </Text>
        </div>
      </Group>
      <ActionIcon
        color="indigo"
        variant="filled"
        mt={10}
        onClick={createChatHandler}
      >
        <IconMessage size="1rem" />
      </ActionIcon>
    </Card>
  );
}
