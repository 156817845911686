import React, { useEffect, useState } from "react";
import { ComponentType } from "react";
import { useDispatch as useReduxDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "./redux/store";
import { Action } from "redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Auth0Provider } from "@auth0/auth0-react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { WebSocketProvider } from "./providers/WebSocketProvider";
import { fetchUserProfile } from "./redux/actions/authActions";
import "./App.css";
import { HeaderMegaMenu } from "./components/HeaderMegaMenu";
import { HeroText } from "./components/HeroText";
import { FooterLinks } from "./components/FooterLinks";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { SurveyAnswers } from "./pages/SurveyAnswers";
import { NewQuestion } from "./pages/NewQuestion";
import { Surveys } from "./pages/Surveys";
import { AuthenticationForm } from "./pages/AuthenticationForm";
import { HomeChat } from "./pages/HomeChat";
import { NewChatAgents } from "./pages/NewChatAgents";
import { Profile } from "./pages/Profile";
import { Insights } from "./pages/Insights";
import { CLIENT_ID, DOMAIN, API_AUDIENCE } from "./config";

type AppDispatch = ThunkDispatch<RootState, void, Action<string>>;

function useDispatch() {
  return useReduxDispatch<AppDispatch>();
}
interface ProtectedComponentProps {
  component: ComponentType<any>;
  [key: string]: any; // To allow additional props
}

const ProtectedComponent: React.FC<ProtectedComponentProps> = ({
  component,
  ...propsForComponent
}) => {
  const Cp = withAuthenticationRequired(component);
  return <Cp {...propsForComponent} />;
};

function AppWrapper() {
  return (
    <Router>
      <Auth0Provider
        domain={DOMAIN}
        clientId={CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: API_AUDIENCE,
        }}
      >
        <WebSocketProvider>
          <App />
        </WebSocketProvider>
      </Auth0Provider>
    </Router>
  );
}

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuthenticated, user, getAccessTokenSilently, isLoading } =
    useAuth0();
  const [token, setToken] = useState("");

  useEffect(() => {
    console.log("isLoading", isLoading);
  }, [isLoading]);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken); // Update the state with the fetched token
      } catch (error) {
        console.error("Error fetching access token:", error);
        setToken(""); // Handle errors (e.g., by setting token to null)
      }
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUserProfile(token));
    }
  }, [token, isAuthenticated, dispatch]);

  const shouldShowFooter =
    !location.pathname.includes("/chat/") &&
    !location.pathname.includes("/insights/");

  return (
    <div className="App">
      <HeaderMegaMenu />
      <Routes>
        <Route path="/" element={<HeroText />} />
        <Route path="/login" element={<AuthenticationForm />} />
        <Route
          path="/past-surveys"
          element={<ProtectedComponent component={Surveys} />}
        />
        <Route
          path="/survey/:questionId"
          element={<ProtectedComponent component={SurveyAnswers} />}
        />
        <Route
          path="/new-question"
          element={<ProtectedComponent component={NewQuestion} />}
        />
        <Route
          path="/new-question/:questionId"
          element={<ProtectedComponent component={NewQuestion} />}
        />
        <Route
          path="/chat/:chatId"
          element={<ProtectedComponent component={HomeChat} />}
        />
        <Route
          path="/chat/new-chat"
          element={<ProtectedComponent component={NewChatAgents} />}
        />
        <Route
          path="/profile"
          element={<ProtectedComponent component={Profile} />}
        />
        <Route
          path="/insights/:agentId"
          element={<ProtectedComponent component={Insights} />}
        />
      </Routes>
      {shouldShowFooter && <FooterLinks data={[]} />}
    </div>
  );
}

export default AppWrapper;
