import { useState } from "react";
import React, { useEffect } from "react";
import {
  Stepper,
  Button,
  Group,
  TextInput,
  Container,
  Title,
  Divider,
  Autocomplete,
  Text,
  Badge,
  useMantineTheme,
  Alert,
  Card,
  Chip,
} from "@mantine/core";
import { IconInfoCircle, IconTrash, IconPackage } from "@tabler/icons-react";

import { useNavigate, useParams } from "react-router-dom";

import { notifications } from "@mantine/notifications";

import { useForm } from "@mantine/form";

import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState, AppThunk } from "../redux/store"; // Adjust the path as necessary
import {
  saveQuestion,
  updateQuestion,
  resetQuestionSaved,
  fetchQuestionById,
} from "../redux/actions/questionActions";

export function NewQuestion() {
  const { questionId } = useParams();
  const form = useForm({
    initialValues: {
      question: "",
      name: "",
    },

    validate: (values) => {
      if (active === 0) {
        return {
          question:
            values.question.trim().length < 6
              ? "Question must include at least 6 characters"
              : null,
        };
      }

      if (active === 1) {
        if (answers.length < 2) {
          notifications.show({
            title: "Not Enough Answers",
            message: "At least two different answers are required",
            color: "red",
          });
          return {
            answers: "At least two different answers are required",
          };
        }
      }

      if (active === 2) {
        if (selectedTags.length < 1) {
          notifications.show({
            title: "No Demographics Selected",
            message: "At least one demographic is required",
            color: "red",
          });
          return {
            selectedTags: "At least one demographic is required",
          };
        }
      }
      return {};
    },
  });
  const navigate = useNavigate();
  const dispatch =
    useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const isQuestionSaved = useSelector(
    (state: RootState) => state.question.saved
  );
  const questionData = useSelector((state: RootState) => state.question);
  const [dataLoaded, setDataLoaded] = useState(false);
  useEffect(() => {
    if (questionId) {
      dispatch(fetchQuestionById(questionId));
      setDataLoaded(false);
    }
  }, [questionId, dispatch]);
  useEffect(() => {
    if (questionData.presaved && !dataLoaded) {
      const question = questionData.presaved;
      form.setFieldValue("question", question.question);
      setAnswers(question.answers);
      setSelectedTags(question.demographics);
      setDataLoaded(true); // this will ensure the form isn't updated in subsequent renders
    }
  }, [questionData, form, dataLoaded]);

  const theme = useMantineTheme();
  const icon = <IconInfoCircle />;

  const handleSaveQuestion = (isPublished = true) => {
    // set a default value to maintain backward compatibility
    let idValue;
    console.log(idValue);
    if (Array.isArray(questionData.data) && questionData.data.length > 0) {
      idValue = questionData.data[0]?.id;
    }
    const questionDataToSubmit = {
      question: form.values.question,
      answers: answers,
      demographics: selectedTags,
      published: isPublished,
      id: idValue,
    };

    if (idValue !== undefined) {
      dispatch(updateQuestion(questionDataToSubmit));
    } else {
      dispatch(saveQuestion(questionDataToSubmit));
    }
  };

  useEffect(() => {
    if (isQuestionSaved) {
      if (
        Array.isArray(questionData.data) &&
        questionData.data.length > 0 &&
        questionData.data[0].published
      ) {
        const id = questionData.data[0].id;
        dispatch(resetQuestionSaved());
        navigate("/survey/" + id);
      } else {
        notifications.show({
          title: "Success",
          message: "Question saved.",
          color: "green",
        });
      }
    }
  }, [isQuestionSaved]);

  const [active, setActive] = useState(0);

  /************************ Possible Answer Options ************************/
  const [answer, setAnswer] = useState("");
  const [answers, setAnswers] = useState<string[]>([]);

  const addAnswerOption = () => {
    const trimmedAnswer = answer.trim();
    if (trimmedAnswer === "") {
      return; // Don't add empty answers
    }

    if (!answers.includes(trimmedAnswer)) {
      setAnswers((prevOptions) => [...prevOptions, trimmedAnswer]);
      setAnswer("");
    } else {
      // Trigger a global alert for duplicate answer
      notifications.show({
        title: "Duplicate Answer",
        message: "This answer option already exists.",
        color: "red",
      });
    }
  };

  const removeAnswerOption = (optionToRemove: string) => {
    setAnswers((prevOptions) =>
      prevOptions.filter((option) => option !== optionToRemove)
    );
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      addAnswerOption();
    }
  };

  /************************ Possible demographics Options ************************/
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>("");

  // Your data source (e.g., an array of available options)
  const options: { value: string; color: string }[] = [
    { value: "Income Bracket: Under $30,000", color: "green" },
    { value: "Income Bracket: $30,000 - $50,000", color: "green" },
    { value: "Race: White", color: "blue" },
    { value: "Race: Black", color: "blue" },
    { value: "Age: 18-24", color: "green" },
    { value: "Age: 25-34", color: "green" },
    { value: "Gender: Male", color: "orange" },
    { value: "Gender: Female", color: "orange" },
    { value: "Education: Primary Education", color: "red" },
    { value: "Education: Master's Degree", color: "red" },
    { value: "Education: Doctoral Degree or Equivalent", color: "red" },
    { value: "Education: No Formal Education", color: "red" },
  ];

  // Function to handle Autocomplete value change
  const handleAutocompleteChange = (value: string) => {
    setInputValue(value);
  };

  // Function to add a tag to the selectedTags array
  const handleAddTag = () => {
    if (inputValue.trim() !== "") {
      if (!selectedTags.includes(inputValue)) {
        setSelectedTags((prevTags) => [...prevTags, inputValue]);
        setInputValue("");
      } else {
        // Trigger a global alert for duplicate demographic
        notifications.show({
          title: "Duplicate Demographic",
          message: "This demographic already exists.",
          color: "red",
        });
        setInputValue(""); // Clear the input field
      }
    }
  };

  // Function to remove a tag from the selectedTags array
  const handleRemoveTag = (tag: string) => {
    setSelectedTags((prevTags) => prevTags.filter((item) => item !== tag));
  };

  const nextStep = () =>
    setActive((current) => {
      if (form.validate().hasErrors) {
        return current;
      }
      return current < 3 ? current + 1 : current;
    });

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const [weight, setWeight] = useState<number>(1000); // initial value

  useEffect(() => {
    const numOfSelectedTags = selectedTags.length;

    // If no demographics are selected, set the weight to 1000
    if (numOfSelectedTags === 0) {
      setWeight(1000);
    } else {
      // Introduce randomness to the decay
      const randomFactor = 0.5 + Math.random(); // This will give a random number between 0.5 and 1.5
      const decayFactor = Math.pow(0.5 * randomFactor, numOfSelectedTags);

      // Calculate the new weight with randomness and ensure it's never below 20
      const newWeight = Math.max(20, Math.round(1000 * decayFactor));
      setWeight(newWeight);
    }
  }, [selectedTags]);
  return (
    <Container size="xl">
      <Title mb={20}>Create a New Question</Title>
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        style={{ flexGrow: 1 }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Group>
            <Button
              variant="light"
              leftIcon={<IconPackage />}
              size="xs"
              onClick={() => handleSaveQuestion(false)}
            >
              Save Draft
            </Button>
            <Button
              variant="light"
              color="red"
              leftIcon={<IconTrash />}
              size="xs"
            >
              Cancel
            </Button>
          </Group>
        </div>
        <Divider my="sm" mx="-md" color="gray.1" />
        <Stepper active={active}>
          <Stepper.Step label="First step" description="Question">
            <Divider my="sm" mx="-md" color="gray.1" />
            <Title order={3} color="dimmed" mb={4}>
              What Is Your Question?
            </Title>

            <Alert variant="light" color="blue" title="" icon={icon}>
              Welcome to CivicSync survey creation tool! In this first step,
              you'll set the foundation for your survey. Start by carefully
              crafting your question – the clearer and more concise it is, the
              better responses you'll receive. Think about what information you
              need and how you want to frame your query. Once you've got your
              question ready, proceed to the next step to specify the possible
              answers.
            </Alert>
            <Divider my="sm" mx="-md" color="gray.1" variant="dashed" />
            <TextInput
              label="Question"
              placeholder="question"
              {...form.getInputProps("question")}
            />
          </Stepper.Step>

          <Stepper.Step label="Second step" description="Answers">
            <Divider my="sm" mx="-md" color="gray.1" />
            <Title order={3} color="dimmed" mb={4}>
              Define Closed Answer Options
            </Title>

            <Alert variant="light" color="blue" title="" icon={icon}>
              In this step, you'll specify the closed answer options for your
              question. Choose response options that align with the nature of
              your query, and ensure they are clear and concise. Whether it's a
              multiple-choice question, a rating scale, or a yes/no format, make
              it easy for respondents to provide their input by defining the
              available choices. Once you've configured your closed answer
              options, you'll be ready to launch your survey and collect
              valuable data.
            </Alert>
            <Divider my="sm" mx="-md" color="gray.1" variant="dashed" />

            <div>
              <TextInput
                value={answer}
                onChange={(event) => setAnswer(event.currentTarget.value)}
                placeholder="Enter an answer option"
                onKeyPress={handleKeyPress} // Handle Enter key press
              />
              <Button onClick={addAnswerOption} style={{ marginTop: "10px" }}>
                Add Answer
              </Button>
              {/* Error message for answer options */}
              <Divider my="sm" mx="-md" color="gray.1" variant="dashed" />
              <Title order={6} color="dimmed" mb={3}>
                {answers.length > 0 ? "Answers Added" : "No Answers Added"}
              </Title>
              <div
                style={{
                  display: "flex", // Use flexbox
                  flexWrap: "wrap", // Wrap chips to next line when needed
                  alignItems: "center", // Center vertically
                }}
              >
                {answers.map((option) => (
                  <Chip
                    key={option}
                    color="blue"
                    style={{
                      marginRight: "8px",
                      marginBottom: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => removeAnswerOption(option)}
                  >
                    {option}
                    <span
                      style={{ marginLeft: "4px", cursor: "pointer" }}
                      onClick={() => removeAnswerOption(option)}
                    >
                      &#10005;
                    </span>
                  </Chip>
                ))}
              </div>
            </div>
          </Stepper.Step>

          <Stepper.Step label="Final step" description="Demographics">
            <Divider my="sm" mx="-md" color="gray.1" />
            <Title order={3} color="dimmed" mb={4}>
              Choose Demographics
            </Title>

            <Alert variant="light" color="blue" title="" icon={icon}>
              In this step, you'll define the specific characteristics of the
              population you want to gather responses from. Carefully select the
              demographics that are most relevant to your research, such as age,
              gender, location, income, or any other pertinent factors. This
              ensures that your survey is answered by the right audience,
              providing you with valuable insights tailored to your objectives.
            </Alert>
            <Divider my="sm" mx="-md" color="gray.1" variant="dashed" />
            <div>
              <Autocomplete
                data={options}
                placeholder="Search and select demographics..."
                multiple
                onChange={handleAutocompleteChange}
              />
              <Button onClick={handleAddTag} style={{ marginTop: "10px" }}>
                Add Demographics
              </Button>
              <Divider my="sm" mx="-md" color="gray.1" variant="dashed" />
              <Title order={6} color="dimmed" mb={3}>
                {selectedTags.length > 0
                  ? "Demographics Selected"
                  : "No Demographics Selected"}
              </Title>
            </div>
            <div>
              {selectedTags.map((tag) => (
                <div
                  key={tag}
                  style={{ display: "inline-block", marginRight: "8px" }}
                >
                  <Badge
                    size="md"
                    color={
                      options.find((option) => option.value === tag)?.color
                    }
                  >
                    {tag}{" "}
                    <span
                      style={{ marginLeft: "4px", cursor: "pointer" }}
                      onClick={() => handleRemoveTag(tag)}
                    >
                      &#10005;
                    </span>
                  </Badge>
                </div>
              ))}
            </div>
            <Divider my="sm" mx="-md" color="gray.1" variant="dashed" />
            <div>
              {selectedTags.length > 0 ? (
                <Text size="sm">
                  Given the demographics you selected, there are{" "}
                  <Badge color="orange">{weight}</Badge> respondents that can
                  answer your question.
                </Text>
              ) : null}
            </div>
          </Stepper.Step>
          <Stepper.Completed>
            <Text mb={4} mt={4}>
              Completed! Here Is a Summary of Your Question:
            </Text>
            <Divider my="sm" mx="-md" color="gray.1" variant="dashed" />
            <Text fw={700} size="xs">
              Question
            </Text>
            <Text size="lg">{form.values.question}</Text>
            <Divider my="sm" mx="-md" color="gray.1" variant="dashed" />
            <Text fw={700} size="xs" mb={8}>
              Possible Answers
            </Text>
            <div
              style={{
                display: "flex", // Use flexbox
                flexWrap: "wrap", // Wrap chips to next line when needed
                alignItems: "center", // Center vertically
              }}
            >
              {answers.map((option) => (
                <Chip
                  key={option}
                  color="blue"
                  style={{
                    marginRight: "8px",
                    marginBottom: "8px",
                  }}
                  onClick={() => removeAnswerOption(option)}
                >
                  {option}
                </Chip>
              ))}
            </div>
            <Divider my="sm" mx="-md" color="gray.1" variant="dashed" />
            <Text fw={700} size="xs" mb={8}>
              Demographics Selected
            </Text>

            <div>
              {selectedTags.map((tag) => (
                <div
                  key={tag}
                  style={{ display: "inline-block", marginRight: "8px" }}
                >
                  <Badge
                    size="md"
                    color={
                      options.find((option) => option.value === tag)?.color
                    }
                  >
                    {tag}
                  </Badge>
                </div>
              ))}
            </div>
            <Divider my="sm" mx="-md" color="gray.1" variant="dashed" />
            <Text size="sm">
              <Badge color="orange">{weight}</Badge> respondents will answer
              your question.
            </Text>
          </Stepper.Completed>
        </Stepper>
        {/* <Group justify="flex-end" mt="xl"></Group> */}
        <Divider my="sm" mx="-md" color="gray.1" />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Group>
            {active !== 0 && (
              <Button variant="default" onClick={prevStep}>
                Back
              </Button>
            )}
            {active !== 3 && <Button onClick={nextStep}>Next step</Button>}
          </Group>

          {active === 3 && (
            <Button color="green" onClick={() => handleSaveQuestion(true)}>
              Get answers!
            </Button>
          )}
        </div>

        {/* <Grid grow gutter="md" style={{ display: "flex", alignItems: "stretch" }}>
        
      </Grid> */}
      </Card>
    </Container>
  );
}
