import {
  createStyles,
  Header,
  Group,
  Button,
  UnstyledButton,
  Text,
  ThemeIcon,
  Divider,
  Box,
  Burger,
  Drawer,
  ScrollArea,
  rem,
} from "@mantine/core";

import { useDisclosure } from "@mantine/hooks";
import {
  IconNotification,
  IconCode,
  IconBook,
  IconFingerprint,
} from "@tabler/icons-react";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { RootState, AppThunk } from "../redux/store"; // Adjust the path as necessary
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import CivicSyncLogo from "./CivicSyncLogo";

const useStyles = createStyles((theme) => ({
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("sm")]: {
      height: rem(42),
      display: "flex",
      alignItems: "center",
      width: "100%",
    },

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    }),
  },

  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
    }),

    "&:active": theme.activeStyles,
  },

  dropdownFooter: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[7]
        : theme.colors.gray[0],
    margin: `calc(${theme.spacing.md} * -1)`,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  hiddenMobile: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },
}));

const mockdata = [
  {
    icon: IconCode,
    title: "API",
    description:
      "Get access to our API and start building your own survey pipelines",
  },
  {
    icon: IconBook,
    title: "Documentation",
    description: "Explore our documentation and learn how to use CivicSync API",
  },
  {
    icon: IconFingerprint,
    title: "Security and Privacy",
    description: "Learn what CivicSync does to protect your data",
  },
  {
    icon: IconNotification,
    title: "Contact us",
    description: "Get in touch with our team",
  },
];

export function HeaderMegaMenu() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const navigate = useNavigate();
  // const isAuthenticated = useSelector(
  //   (state: RootState) => state.auth.isAuthenticated
  // );
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
  const { classes, theme } = useStyles();
  const dispatch =
    useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();

  const links = mockdata.map((item) => (
    <UnstyledButton className={classes.subLink} key={item.title}>
      <Group noWrap align="flex-start">
        <ThemeIcon size={34} variant="default" radius="md">
          <item.icon size={rem(22)} color={theme.fn.primaryColor()} />
        </ThemeIcon>
        <div>
          <Text size="sm" fw={500}>
            {item.title}
          </Text>
          <Text size="xs" color="dimmed">
            {item.description}
          </Text>
        </div>
      </Group>
    </UnstyledButton>
  ));

  return (
    <Box>
      <Header height={60} px="md">
        <Group position="apart" sx={{ height: "100%" }}>
          <a href="/">
            <CivicSyncLogo size={"30px"} includeName={true} />
          </a>
          <Group className={classes.hiddenMobile}>
            {isAuthenticated ? (
              <div>
                <Button
                  mr={5}
                  variant="light"
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  Profile
                </Button>
                <Button
                  variant="default"
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  Sign out
                </Button>
              </div>
            ) : (
              <Button variant="default" onClick={() => loginWithRedirect()}>
                Log in
              </Button>
            )}
          </Group>

          <Burger
            opened={drawerOpened}
            onClick={toggleDrawer}
            className={classes.hiddenDesktop}
          />
        </Group>
      </Header>

      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Navigation"
        className={classes.hiddenDesktop}
        zIndex={1000000}
      >
        <ScrollArea h={`calc(100vh - ${rem(60)})`} mx="-md">
          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />

          <a
            href="#"
            className={classes.link}
            onClick={() => {
              navigate("/");
              closeDrawer();
            }}
          >
            Home
          </a>

          <a
            href="#"
            className={classes.link}
            onClick={() => {
              navigate("/past-surveys");
              closeDrawer();
            }}
          >
            Past Surveys and Focus Groups
          </a>

          <a
            href="#"
            className={classes.link}
            onClick={() => {
              navigate("/chat/new-chat");
              closeDrawer();
            }}
          >
            Create New Focus Group
          </a>

          <Divider
            my="sm"
            color={theme.colorScheme === "dark" ? "dark.5" : "gray.1"}
          />

          <Group position="center" grow pb="xl" px="md">
            {isAuthenticated ? (
              <>
                <Button
                  mr={5}
                  variant="light"
                  onClick={() => {
                    closeDrawer();
                    navigate("/profile");
                  }}
                >
                  Profile
                </Button>
                <Button
                  variant="default"
                  onClick={() =>
                    logout({
                      logoutParams: { returnTo: window.location.origin },
                    })
                  }
                >
                  Sign out
                </Button>
              </>
            ) : (
              <Button variant="default" onClick={() => loginWithRedirect()}>
                Log in
              </Button>
            )}
          </Group>
        </ScrollArea>
      </Drawer>
    </Box>
  );
}
