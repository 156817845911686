import SurveyPreview from "../components/SurveyPreview";
import {
  Grid,
  Container,
  Title,
  Divider,
  createStyles,
  rem,
  Input,
  Textarea,
  Select,
  Table,
  Button,
  ActionIcon,
  Group,
  TextInput,
  Paper,
  Loader,
  Alert,
} from "@mantine/core";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { notifications } from "@mantine/notifications";
import countries from "../resources/countries.json";
import education from "../resources/education.json";
import employment from "../resources/employment.json";
import martial from "../resources/martial.json";
import { BASE_API_URL } from "../config";
import {
  IconTrash,
  IconFileDownload,
  IconPlus,
  IconX,
  IconCheck,
  IconEdit,
  IconAlertCircle,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState, AppThunk } from "../redux/store"; // Adjust the path as necessary

import {
  updateUserProfile,
  createIdentifier,
  ListPluginId,
  createApiKey,
  logout,
} from "../redux/actions/authActions";

export function Profile() {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const dispatch =
    useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const user = useSelector((state: RootState) => state.auth.userData);
  const loading = useSelector((state: RootState) => state.auth.loading);
  const loadingCertificate = useSelector(
    (state: RootState) => state.auth.loadingCertificate
  );
  const errorCode = useSelector((state: RootState) => state.auth.errorCode);
  const error = useSelector((state: RootState) => state.auth.error);
  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken); // Update the state with the fetched token
      } catch (error) {
        console.error("Error fetching access token:", error);
        setToken(""); // Handle errors (e.g., by setting token to null)
      }
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (errorCode !== undefined) {
      if (errorCode !== 200 && errorCode !== 401) {
        console.log("User updated! 4");
        // Revert Personal Information section
        console.log(errorCode, error);
        console.log("Reverting personal info");
        setTempPersonalInfo({
          fullName: user?.fullname || "",
          yearOfBirth: user?.year_of_birth?.toString() || "",
          gender: user?.gender || "",
          countryOfBirth: user?.country_of_birth || "",
        });

        // Revert Professional Background section
        setTempProfessionalInfo({
          educationalLevel: user?.education_level || "",
          employmentStatus: user?.employment_status || "",
          annualIncome: user?.household_income?.toString() || "",
        });

        // Revert Personal Lifestyle and Interests section
        setTempLifestyleInfo({
          martialStatus: user?.marital_status || "",
          interests: user?.interests || "",
        });

        // Handle CivicSync identifiers reverting
        console.log("Reverting changes due to error");
        if (Array.isArray(user?.plugin_id)) {
          // If plugin_id is an array, filter active ones, and map to your desired structure
          const activeIdentifiers = user?.plugin_id
            .filter((id) => typeof id === "object" && id.active)
            .map((id) => ({ plugin_id: id.plugin_id, active: id.active }));
          if (activeIdentifiers && activeIdentifiers.length > 0) {
            setIdentifiers(activeIdentifiers);
          }
        } else if (typeof user?.plugin_id === "string") {
          // If it's a string, convert it to your expected object array format
          setIdentifiers([{ plugin_id: user.plugin_id, active: true }]);
        }

        if (Array.isArray(user?.api_key)) {
          // If plugin_id is an array, filter active ones, and map to your desired structure
          const activeIdentifiers = user?.api_key
            .filter((id) => typeof id === "object" && id.active)
            .map((id) => ({ plugin_id: id.plugin_id, active: id.active }));
          if (activeIdentifiers && activeIdentifiers.length > 0) {
            setIdentifiersApi(activeIdentifiers);
          }
        } else if (typeof user?.plugin_id === "string") {
          // If it's a string, convert it to your expected object array format
          setIdentifiersApi([{ plugin_id: user.plugin_id, active: true }]);
        }

        notifications.show({
          title: "Error",
          message: "An error occurred. Please try again later.",
          color: "red",
        });
      } else if (errorCode === 401) {
        console.log("Logging out due to 401");
        dispatch(logout());
        notifications.show({
          title: "Session Expired",
          message: "Please login.",
          color: "orange",
        });
        navigate("/");
      } else {
        notifications.show({
          title: "Success",
          message: "Information updated successfully",
          color: "green",
        });
      }
    }
  }, [error, errorCode, user]);
  // Handle modifications CivicSync Identifiers
  const [identifiers, setIdentifiers] = useState<ListPluginId[]>([]);
  const [identifiersApi, setIdentifiersApi] = useState<ListPluginId[]>([]);

  // Initialize identifiers from user data
  useEffect(() => {
    if (user?.plugin_id) {
      if (typeof user.plugin_id === "string") {
        // If it's a string, directly use the string as an identifier
        setIdentifiers([{ plugin_id: user.plugin_id, active: true }]);
      } else if (Array.isArray(user.plugin_id)) {
        // If it's an array, filter by active status
        const activeIdentifiers =
          user?.plugin_id
            .filter((id) => typeof id === "object" && id.active)
            .map((id) => ({ plugin_id: id.plugin_id, active: id.active })) ||
          []; // Default to an empty array if the result is falsy
        if (activeIdentifiers.length > 0) {
          setIdentifiers(activeIdentifiers);
        } else {
          setIdentifiers([]);
        }
      }
    }
  }, [user]);

  // Initialize identifiers from user data
  useEffect(() => {
    if (user?.api_key) {
      // If it's an array, filter by active status
      const activeIdentifiers =
        user?.api_key
          .filter((id) => typeof id === "object" && id.active)
          .map((id) => ({ plugin_id: id.plugin_id, active: id.active })) || []; // Default to an empty array if the result is falsy
      if (activeIdentifiers.length > 0) {
        setIdentifiersApi(activeIdentifiers);
      } else {
        setIdentifiersApi([]);
      }
    }
  }, [user]);

  const handleSaveCivicSyncIdentifier = () => {
    dispatch(createIdentifier(token));
  };

  const handleDeleteIdentifier = (pluginIdToDelete: string) => {
    const updatedIdentifiers = identifiers.map((id) => {
      if (id.plugin_id === pluginIdToDelete) {
        return { ...id, active: false };
      }
      return id;
    });

    // Update local state
    setIdentifiers(updatedIdentifiers);

    // Update in Redux and backend
    dispatch(updateUserProfile({ plugin_id: updatedIdentifiers }, token));
  };

  const handleDeleteIdentifierApi = (pluginIdToDelete: string) => {
    if (user?.api_key) {
      const updatedIdentifiers = user?.api_key.map((id) => {
        if (id.plugin_id === pluginIdToDelete) {
          return { ...id, active: false };
        }
        return id;
      });

      // Update local state
      // setIdentifiersApi(updatedIdentifiers);

      // Update in Redux and backend
      dispatch(updateUserProfile({ api_key: updatedIdentifiers }, token));
    }
  };

  const downloadCertificateOne = async (userId: string) => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}/agent/download-certificate/${userId}.ovpn`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob", // Important for file download
        }
      );

      // Create a link element to trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${userId}.ovpn`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const downloadCertificateTwo = async () => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}/agent/download-certificate/civicsync.cer`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "blob", // Important for file download
        }
      );

      // Create a link element to trigger the download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `civicsync.cer`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error);
    }
  };

  const rows = identifiers.map((identifier, index) => (
    <tr key={index}>
      <td>{identifier.plugin_id}</td>

      <td>
        <ActionIcon
          variant="subtle"
          onClick={() => downloadCertificateOne(identifier.plugin_id)}
        >
          <IconFileDownload size={16} />
        </ActionIcon>
      </td>
      <td>
        <ActionIcon variant="subtle" onClick={() => downloadCertificateTwo()}>
          <IconFileDownload size={16} />
        </ActionIcon>
      </td>
      <td>
        <ActionIcon
          variant="subtle"
          onClick={() => handleDeleteIdentifier(identifier.plugin_id)}
        >
          <IconTrash size={16} />
        </ActionIcon>
      </td>
    </tr>
  ));

  const rowsApi = identifiersApi.map((identifier, index) => (
    <tr key={index}>
      <td>{identifier.plugin_id}</td>
      <td>
        <ActionIcon
          variant="subtle"
          onClick={() => handleDeleteIdentifierApi(identifier.plugin_id)}
        >
          <IconTrash size={16} />
        </ActionIcon>
      </td>
    </tr>
  ));

  const [personalInfo, setPersonalInfo] = useState({
    fullName: "",
    yearOfBirth: "",
    gender: "",
    countryOfBirth: "",
  });
  const [editPersonalInfo, setEditPersonalInfo] = useState(false);
  const [tempPersonalInfo, setTempPersonalInfo] = useState({ ...personalInfo });

  const [professionalInfo, setProfessionalInfo] = useState({
    educationalLevel: "",
    employmentStatus: "",
    annualIncome: "",
  });
  const [editProfessionalInfo, setEditProfessionalInfo] = useState(false);
  const [tempProfessionalInfo, setTempProfessionalInfo] = useState({
    ...professionalInfo,
  });

  const [lifestyleInfo, setLifestyleInfo] = useState({
    martialStatus: "",
    interests: "",
  });
  const [editLifestyleInfo, setEditLifestyleInfo] = useState(false);
  const [tempLifestyleInfo, setTempLifestyleInfo] = useState({
    ...lifestyleInfo,
  });

  // Effect hook to update state based on the user object
  useEffect(() => {
    if (user) {
      const updatedInfo = {
        fullName: user.fullname || "",
        yearOfBirth: user.year_of_birth?.toString() || "",
        gender: user.gender || "",
        countryOfBirth: user.country_of_birth || "",
      };

      // Update both personalInfo and tempPersonalInfo with user data
      setPersonalInfo(updatedInfo);
      setTempPersonalInfo(updatedInfo);

      const updatedProfessionalInfo = {
        educationalLevel: user?.education_level || "",
        employmentStatus: user?.employment_status || "",
        annualIncome: user?.household_income?.toString() || "",
      };

      setProfessionalInfo(updatedProfessionalInfo);
      setTempProfessionalInfo(updatedProfessionalInfo);

      const updatedLifestyleInfo = {
        martialStatus: user?.marital_status || "",
        interests: user?.interests || "",
      };

      setLifestyleInfo(updatedLifestyleInfo);
      setTempLifestyleInfo(updatedLifestyleInfo);
    }
  }, [user]); // Depend on user to trigger this effect

  return (
    <Container size="xl">
      <Title mb={20}>Settings</Title>
      <Paper withBorder shadow="xs" p="md" radius="md" mb="lg">
        <Group position="apart">
          <Title order={3}>Personal Information</Title>
          {!editPersonalInfo && (
            <ActionIcon
              variant="light"
              color="blue"
              onClick={() => setEditPersonalInfo(true)}
            >
              <IconEdit size={16} />
            </ActionIcon>
          )}
        </Group>
        <Grid>
          <Grid.Col md={12} lg={12}>
            <Input.Wrapper label="Full Name">
              <Input
                placeholder="Full Name"
                value={tempPersonalInfo.fullName}
                onChange={(e) =>
                  setTempPersonalInfo({
                    ...tempPersonalInfo,
                    fullName: e.target.value,
                  })
                }
                disabled={!editPersonalInfo}
              />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={12} lg={12}>
            <Input.Wrapper label="Year of Birth">
              <Input
                placeholder="Year of Birth"
                value={tempPersonalInfo.yearOfBirth}
                onChange={(e) =>
                  setTempPersonalInfo({
                    ...tempPersonalInfo,
                    yearOfBirth: e.target.value,
                  })
                }
                disabled={!editPersonalInfo}
              />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={12} lg={12}>
            <Input.Wrapper label="Gender">
              <Select
                placeholder="Gender"
                value={tempPersonalInfo.gender}
                onChange={(value) =>
                  setTempPersonalInfo({
                    ...tempPersonalInfo,
                    gender: value || "",
                  })
                }
                disabled={!editPersonalInfo}
                data={[
                  { value: "Female", label: "Female" },
                  { value: "Male", label: "Male" },
                  { value: "Nonbinary", label: "Nonbinary" },
                  { value: "Other", label: "Other" },
                  { value: "Prefer not to say", label: "Prefer not to say" },
                ]}
              />
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col md={12} lg={12}>
            <Input.Wrapper label="Country of Birth">
              <Select
                placeholder="Country of Birth"
                value={tempPersonalInfo.countryOfBirth}
                onChange={(value) =>
                  setTempPersonalInfo({
                    ...tempPersonalInfo,
                    countryOfBirth: value || "",
                  })
                }
                disabled={!editPersonalInfo}
                data={countries.map((country) => ({
                  value: country,
                  label: country,
                }))}
              />
            </Input.Wrapper>
          </Grid.Col>
        </Grid>
        {editPersonalInfo && (
          <Group position="right" mt="md">
            <Button
              color="green"
              onClick={() => {
                setPersonalInfo(tempPersonalInfo);
                setEditPersonalInfo(false);
                dispatch(
                  updateUserProfile(
                    {
                      fullname: tempPersonalInfo.fullName,
                      year_of_birth: parseInt(tempPersonalInfo.yearOfBirth, 10),
                      gender: tempPersonalInfo.gender,
                      country_of_birth: tempPersonalInfo.countryOfBirth,
                    },
                    token
                  )
                );
              }}
              variant="light"
            >
              <IconCheck size={14} /> Save
            </Button>
            <Button
              color="red"
              onClick={() => {
                setPersonalInfo(personalInfo);
                setEditPersonalInfo(false);
              }}
              variant="light"
            >
              <IconX size={14} /> Cancel
            </Button>
          </Group>
        )}
      </Paper>
      <Paper withBorder shadow="xs" p="md" radius="md" mb="lg">
        <Group position="apart">
          <Title order={3}>Professional Background</Title>
          {!editProfessionalInfo && (
            <ActionIcon
              variant="light"
              color="blue"
              onClick={() => setEditProfessionalInfo(true)}
            >
              <IconEdit size={16} />
            </ActionIcon>
          )}
        </Group>
        <Grid>
          {/* Educational Level */}
          <Grid.Col md={12} lg={12}>
            <Input.Wrapper label="Educational Level">
              <Select
                placeholder="Pick value"
                value={tempProfessionalInfo.educationalLevel}
                onChange={(value) =>
                  setTempProfessionalInfo({
                    ...tempProfessionalInfo,
                    educationalLevel: value || "",
                  })
                }
                data={education}
                disabled={!editProfessionalInfo}
              />
            </Input.Wrapper>
          </Grid.Col>
          {/* Employment Status */}
          <Grid.Col md={12} lg={12}>
            <Input.Wrapper label="Employment">
              <Select
                placeholder="Pick value"
                value={tempProfessionalInfo.employmentStatus}
                onChange={(value) =>
                  setTempProfessionalInfo({
                    ...tempProfessionalInfo,
                    employmentStatus: value || "",
                  })
                }
                data={employment}
                disabled={!editProfessionalInfo}
              />
            </Input.Wrapper>
          </Grid.Col>
          {/* Annual Household Income */}
          <Grid.Col md={12} lg={12}>
            <Input.Wrapper label="Annual Household Income">
              <Input
                placeholder="Annual household income"
                value={tempProfessionalInfo.annualIncome}
                onChange={(e) =>
                  setTempProfessionalInfo({
                    ...tempProfessionalInfo,
                    annualIncome: e.target.value,
                  })
                }
                disabled={!editProfessionalInfo}
              />
            </Input.Wrapper>
          </Grid.Col>
        </Grid>
        {editProfessionalInfo && (
          <Group position="right" mt="md">
            <Button
              variant="light"
              color="green"
              onClick={() => {
                setProfessionalInfo(tempProfessionalInfo);
                setEditProfessionalInfo(false);
                dispatch(
                  updateUserProfile(
                    {
                      education_level: tempProfessionalInfo.educationalLevel,
                      employment_status: tempProfessionalInfo.employmentStatus,
                      household_income: tempProfessionalInfo.annualIncome,
                    },
                    token
                  )
                );
              }}
            >
              <IconCheck size={14} /> Save
            </Button>
            <Button
              variant="light"
              color="red"
              onClick={() => {
                setTempProfessionalInfo(professionalInfo);
                setEditProfessionalInfo(false);
              }}
            >
              <IconX size={14} /> Cancel
            </Button>
          </Group>
        )}
      </Paper>
      <Paper withBorder shadow="xs" p="md" radius="md" mb="lg">
        <Group position="apart">
          <Title order={3}>Personal Lifestyle and Interests</Title>
          {!editLifestyleInfo && (
            <ActionIcon
              variant="light"
              color="blue"
              onClick={() => setEditLifestyleInfo(true)}
            >
              <IconEdit size={16} />
            </ActionIcon>
          )}
        </Group>
        <Grid>
          {/* Martial Status */}
          <Grid.Col md={12} lg={12}>
            <Input.Wrapper
              label="Marital Status"
              description="Select your marital status"
            >
              <Select
                placeholder="Pick value"
                value={tempLifestyleInfo.martialStatus}
                onChange={(value) =>
                  setTempLifestyleInfo({
                    ...tempLifestyleInfo,
                    martialStatus: value || "",
                  })
                }
                data={martial}
                disabled={!editLifestyleInfo}
              />
            </Input.Wrapper>
          </Grid.Col>
          {/* Interests and Hobbies */}
          <Grid.Col md={12} lg={12}>
            <Input.Wrapper
              label="Interests and Hobbies"
              description="Describe your interests and hobbies"
            >
              <Textarea
                placeholder="Interests and Hobbies"
                value={tempLifestyleInfo.interests}
                onChange={(e) =>
                  setTempLifestyleInfo({
                    ...tempLifestyleInfo,
                    interests: e.target.value,
                  })
                }
                disabled={!editLifestyleInfo}
              />
            </Input.Wrapper>
          </Grid.Col>
        </Grid>
        {editLifestyleInfo && (
          <Group position="right" mt="md">
            <Button
              variant="light"
              color="green"
              onClick={() => {
                setLifestyleInfo(tempLifestyleInfo);
                setEditLifestyleInfo(false);
                dispatch(
                  updateUserProfile(
                    {
                      marital_status: tempLifestyleInfo.martialStatus,
                      interests: tempLifestyleInfo.interests,
                    },
                    token
                  )
                );
              }}
            >
              <IconCheck size={14} /> Save
            </Button>
            <Button
              variant="light"
              color="red"
              onClick={() => {
                setTempLifestyleInfo(lifestyleInfo);
                setEditLifestyleInfo(false);
              }}
            >
              <IconX size={14} /> Cancel
            </Button>
          </Group>
        )}
      </Paper>
      <Paper withBorder shadow="xs" p="md" radius="md" mb="lg">
        {identifiers.length === 0 && (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Create your own replica!"
            color="yellow"
          >
            Create your own CivicSync virtual replica and start chatting with it
            today. By creating an identifier, you accept CivicSync's{" "}
            <a href="https://civicsync.com/terms/" target="_blank">
              Terms of Use and Privacy Policy.
            </a>
            {loadingCertificate && (
              <div>
                <Loader mt={10} />
              </div>
            )}
            {identifiers.length === 0 && !loadingCertificate && (
              <Group position="left" mb="md" mt={10}>
                <Button
                  variant="light"
                  leftIcon={<IconPlus />}
                  onClick={() => handleSaveCivicSyncIdentifier()}
                >
                  Create Identifier
                </Button>
              </Group>
            )}
          </Alert>
        )}
        {identifiers.length != 0 && (
          <>
            <Grid>
              <Grid.Col md={12} lg={12}>
                <Table>
                  <thead>
                    <tr>
                      <th>CivicSync Identifier</th>
                      <th>Config 1</th>
                      <th>Config 2</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </Table>
              </Grid.Col>
            </Grid>
            <Alert
              mt={20}
              icon={<IconAlertCircle size="1rem" />}
              title="What's next?"
              color="blue"
            >
              You will need to download the configuration files. To set up your
              replica on your mobile phone or personal computer, follow the
              installation steps outlined in this{" "}
              <a href="https://civicsync.com/terms/" target="_blank">
                guide.
              </a>
            </Alert>
          </>
        )}
      </Paper>
      <Paper withBorder shadow="xs" p="md" radius="md" mb="lg">
        {identifiers.length === 0 && (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Create your own replica!"
            color="yellow"
          >
            Create your own CivicSync virtual replica and start chatting with it
            today. By creating an identifier, you accept CivicSync's{" "}
            <a href="https://civicsync.com/terms/" target="_blank">
              Terms of Use and Privacy Policy.
            </a>
            {loadingCertificate && (
              <div>
                <Loader mt={10} />
              </div>
            )}
            {identifiers.length === 0 && !loadingCertificate && (
              <Group position="left" mb="md" mt={10}>
                <Button
                  variant="light"
                  leftIcon={<IconPlus />}
                  onClick={() => handleSaveCivicSyncIdentifier()}
                >
                  Create Identifier
                </Button>
              </Group>
            )}
          </Alert>
        )}
        {identifiersApi.length != 0 && (
          <>
            <Grid>
              <Grid.Col md={12} lg={12}>
                <Table>
                  <thead>
                    <tr>
                      <th>API Key</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>{rowsApi}</tbody>
                </Table>
              </Grid.Col>
            </Grid>
            <Button
              color="gray"
              onClick={() => {
                dispatch(createApiKey(token));
              }}
              variant="light"
            >
              <IconPlus size={14} /> Add Key
            </Button>
          </>
        )}
        {identifiersApi.length === 0 && (
          <Alert
            icon={<IconAlertCircle size="1rem" />}
            title="Create an API key"
            color="gray"
          >
            Do not share your API key with others, or expose it in the browser
            or other client-side code. In order to protect the security of your
            account, CivicSync may also automatically disable any API key that
            has leaked publicly.
            {identifiersApi.length === 0 && (
              <Group position="left" mb="md" mt={10}>
                <Button
                  variant="light"
                  leftIcon={<IconPlus />}
                  onClick={() => dispatch(createApiKey(token))}
                >
                  Create API Key
                </Button>
              </Group>
            )}
          </Alert>
        )}
      </Paper>
    </Container>
  );
}
