import React, { useState, useEffect } from "react";
import {
  TextInput,
  MultiSelect,
  Col,
  Card,
  Title,
  Button,
  Group,
  Text,
  Divider,
} from "@mantine/core";
import { Snapshots } from "./Snapshots";
import { HiOutlineRefresh, HiOutlineArchive } from "react-icons/hi";

interface SurveyFormProps {
  initialQuestion?: string;
  initialOptions?: string[];
  initialDemographics?: string[];
}

const SurveyForm: React.FC<SurveyFormProps> = ({
  initialQuestion = "",
  initialOptions = [],
  initialDemographics = [],
}) => {
  const [question, setQuestion] = useState(initialQuestion);
  const [options, setOptions] = useState<string[]>(initialOptions);
  const [demographics, setDemographics] =
    useState<string[]>(initialDemographics);
  useEffect(() => {
    setQuestion(initialQuestion);
    setOptions(initialOptions);
    setDemographics(initialDemographics);
  }, [initialQuestion, initialOptions, initialDemographics]);

  return (
    <Card>
      <Title order={4} align="left">
        Survey Form
      </Title>

      <TextInput
        mb="lg"
        style={{ textAlign: "left", height: "40px" }}
        label="Question"
        placeholder="Enter your question here"
        value={question}
        onChange={(event) => setQuestion(event.currentTarget.value)}
      />

      <MultiSelect
        mb="lg"
        disabled
        style={{ textAlign: "left" }}
        data={options}
        label="Possible Answers"
        placeholder="Select options"
        value={options}
        onChange={setOptions}
      />

      <MultiSelect
        style={{ textAlign: "left" }}
        disabled
        data={demographics}
        label="Demographics"
        placeholder="Select demographics"
        value={demographics}
        onChange={setDemographics}
      />
      <Group position="left" mt="xl">
        <Button variant="outline">Update Survey</Button>
      </Group>
      <Divider my="sm" mx="-md" color="gray.1" />
      <Title order={4} align="left" mb={10}>
        Export
      </Title>
      <div style={{ display: "flex", alignItems: "left" }}>
        <Button
          size="xs"
          color="yellow"
          variant="light"
          leftIcon={<HiOutlineArchive />}
          style={{ marginRight: "1rem" }}
        >
          Download CSV
        </Button>
      </div>
      <Divider my="sm" mx="-md" color="gray.1" />
      <Title order={4} align="left">
        Snapshots
      </Title>
      <Snapshots
        data={[
          { title: "July 18, 2023 09:30 AM" },
          { title: "June 3, 2023 05:15 PM" },
          { title: "May 15, 2023 10:15 PM" },
        ]}
      />
    </Card>
  );
};

export default SurveyForm;
