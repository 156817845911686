import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  ActionIcon,
  ScrollArea,
  Avatar,
} from "@mantine/core";
import React, { useEffect } from "react";

import CivicSyncLogo from "./CivicSyncLogo";
import { Chip, Divider } from "@mantine/core";
import { ArrowRightIcon } from "@modulz/radix-icons";
import { useNavigate } from "react-router-dom";

import { IconHeart } from "@tabler/icons-react";
import classes from "../styles/chat/BadgeCard.module.css";

import DateFormatter from "./DateFormatter";

interface AgentPreviewProps {
  isSelected: boolean;
  onSelect: any;
  agentId: string;
  fullName: string;
  bio: string;
  yearOfBirth: number;
  gender: string;
  race: string;
  citizenship: string;
  countryOfBirth: string;
  countryOfResidence: string;
  cityOfResidence: string;
  educationLevel: string;
  householdIncome: string;
  employmentStatus: string;
  martialStatus: string;
  interests: string;
  additionalInfo: string;
}

const AgentPreview: React.FC<AgentPreviewProps> = ({
  onSelect,
  isSelected,
  agentId,
  fullName,
  bio,
  yearOfBirth,
  cityOfResidence,
  educationLevel,
  householdIncome,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);
  {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return (
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        style={{ flexGrow: 1 }}
      >
        <Group spacing="xs">
          <Avatar
            src={
              "https://api.dicebear.com/8.x/lorelei-neutral/svg?backgroundColor=b6e3f4,c0aede,d1d4f9&seed=" +
              fullName
            }
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Text
              fz="lg"
              align="left"
              fw={500}
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => navigate("/insights/" + agentId)}
            >
              {fullName}
            </Text>
          </div>
        </Group>
        <Divider my="sm" mx="-md" color="gray.1" />
        <Group spacing="xs">
          <ScrollArea h={250}>
            <Text fz="sm" mt="xs">
              {bio}
            </Text>
          </ScrollArea>
        </Group>
        <Divider my="sm" mx="-md" color="gray.1" />
        <Chip.Group>
          <Group position="left" spacing="xs">
            <Chip defaultChecked color="red" variant="light">
              <span className="truncate">
                {currentYear - yearOfBirth} years old
              </span>
            </Chip>
            <Chip defaultChecked color="green" variant="light">
              <span>{cityOfResidence}</span>
            </Chip>
            <Chip defaultChecked color="orange" variant="light">
              <span>{educationLevel}</span>
            </Chip>
            <Chip defaultChecked color="pink" variant="light">
              <span>{householdIncome}</span>
            </Chip>
          </Group>
        </Chip.Group>
        <Divider my="sm" mx="-md" color="gray.1" />
        <Group position="right">
          <Button
            variant="outline"
            color={isSelected ? "orange" : "teal"}
            onClick={onSelect}
          >
            {isSelected ? "Remove" : "Add"}
          </Button>
          {/* <Button
            variant="light"
            color="blue"
            mt="md"
            radius="md"
            onClick={() =>
              navigate(published ? "/survey/" + id : "/new-question/" + id)
            }
          >
            {published ? "Explore" : "Edit"}
          </Button> */}
        </Group>
      </Card>
    );
  }
};
export default AgentPreview;
