import { useToggle, upperFirst } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState, AppThunk } from "../redux/store"; // Adjust the path as necessary

import { googleAuth } from "../redux/actions/authActions";

import { Text, Paper, PaperProps } from "@mantine/core";

export function AuthenticationForm(props: PaperProps) {
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(googleAuth(token));
        setAccessToken(token);
      } catch (e) {
        console.error(e);
      }
    };

    getToken();
  }, [getAccessTokenSilently]);
  const dispatch =
    useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  return (
    <div style={{ maxWidth: "600px", margin: "0 auto" }}>
      <Paper
        radius="md"
        p="xl"
        withBorder
        shadow="xs"
        style={{ width: "100%" }}
        {...props}
      >
        <Text size="lg" fw={500}>
          Welcome to CivicSync
        </Text>
      </Paper>
    </div>
  );
}
