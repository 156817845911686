import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import React, { useEffect } from "react";
import { IconClipboardList } from "@tabler/icons-react";

import CivicSyncLogo from "./CivicSyncLogo";
import { Chip, Divider } from "@mantine/core";
import { ArrowRightIcon } from "@modulz/radix-icons";
import { useNavigate } from "react-router-dom";

interface SurveyPreviewProps {
  question: string;
  options: string;
  confidence: string;
  demographics: string;
  published: boolean;
  id?: string | boolean;
}

const SurveyPreview: React.FC<SurveyPreviewProps> = ({
  question,
  options,
  confidence,
  demographics,
  published,
  id,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);
  {
    return (
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        style={{ flexGrow: 1 }}
      >
        <Group position="apart">
          <Tooltip label="Survey" position="bottom" withArrow>
            <ThemeIcon color="lime" variant="light">
              <IconClipboardList size={20} />
            </ThemeIcon>
          </Tooltip>
          <Badge color={published ? "blue" : "orange"} variant="light">
            {published ? "Published" : "Draft"}
          </Badge>
        </Group>
        <Divider my="sm" mx="-md" color="gray.1" />
        <Group position="apart" mt="md" mb="xs">
          <Text align="left" size="sm" weight={700} color="blue">
            Question
          </Text>
        </Group>
        <Group spacing="xs">
          <Text align="left" weight={500}>
            {question}
          </Text>
        </Group>
        <Divider my="sm" mx="-md" color="gray.1" />
        <Group spacing="xs" mt="md" mb="xs">
          <Text align="left" size="sm" weight={700} color="blue">
            Answers
          </Text>
        </Group>
        <Group spacing="xs">
          <Chip.Group>
            <Group position="left" spacing="xs">
              {options.split(", ").length === 1 &&
              options.split(", ")[0] == "" ? (
                <Text size="xs">N/A</Text>
              ) : (
                options.split(", ").map((option, demoIndex) => (
                  <Chip value={demoIndex} key={demoIndex}>
                    {option}
                  </Chip>
                ))
              )}
            </Group>
          </Chip.Group>
        </Group>
        <Divider my="sm" mx="-md" color="gray.1" />
        <Group spacing="xs" mt="md" mb="xs">
          <Text align="left" size="sm" weight={700} color="blue">
            Demographics
          </Text>
        </Group>
        <Group spacing="xs">
          <Chip.Group>
            <Group position="left" spacing="xs">
              {demographics.split(", ").length === 1 &&
              demographics.split(", ")[0] == "" ? (
                <Text size="xs">N/A</Text>
              ) : (
                demographics.split(", ").map((option, demoIndex) => (
                  <Chip value={demoIndex} key={demoIndex}>
                    {option}
                  </Chip>
                ))
              )}
            </Group>
          </Chip.Group>
        </Group>
        <Divider my="sm" mx="-md" color="gray.1" />
        <Group position="right">
          <Button
            variant="light"
            color="blue"
            mt="md"
            radius="md"
            onClick={() =>
              navigate(published ? "/survey/" + id : "/new-question/" + id)
            }
          >
            {published ? "Explore" : "Edit"}
          </Button>
        </Group>
      </Card>
    );
  }
};
export default SurveyPreview;
