import SurveyPreview from "../components/SurveyPreview";
import ChatPreview from "../components/ChatPreview";
import {
  Card,
  Grid,
  Container,
  Col,
  Title,
  Divider,
  Loader,
  Alert,
  createStyles,
  Button,
  rem,
  Text,
  Box,
} from "@mantine/core";
import { useAuth0 } from "@auth0/auth0-react";
import { IconInfoCircle } from "@tabler/icons-react";
import { InputWithButton } from "../components/InputWithButton";
import { useNavigate } from "react-router-dom";
import { fetchChats } from "../redux/actions/chatActions";
import { ChatData as ChatDataType } from "../redux/actions/chatActions";
import { QuestionData as QuestionDataType } from "../redux/actions/questionActions";
import { logout } from "../redux/actions/authActions";
import SelectableTag from "../components/SelectableTag";
import debounce from "lodash/debounce"; // Assuming lodash is installed, otherwise you can write your own debounce function

import { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState, AppThunk } from "../redux/store"; // Adjust the path as necessary
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
  },

  dots: {
    position: "absolute",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[1],

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  dotsLeft: {
    left: 0,
    top: 0,
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      textAlign: "left",
    },
  },

  highlight: {
    color:
      theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },

  description: {
    textAlign: "center",

    [theme.fn.smallerThan("xs")]: {
      textAlign: "left",
      fontSize: theme.fontSizes.md,
    },
  },

  controls: {
    marginTop: theme.spacing.lg,
    display: "flex",
    justifyContent: "center",

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  control: {
    "&:not(:first-of-type)": {
      marginLeft: theme.spacing.md,
    },

    [theme.fn.smallerThan("xs")]: {
      height: rem(42),
      fontSize: theme.fontSizes.md,

      "&:not(:first-of-type)": {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },
}));

type CombinedItemType = QuestionDataType | ChatDataType;

export function Surveys() {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");
  const icon = <IconInfoCircle />;
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch =
    useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();

  // Questions State
  const questions = useSelector((state: RootState) => state.question.questions);
  const errorQuestion = useSelector((state: RootState) => state.question.error);
  const errorCodeQuestion = useSelector(
    (state: RootState) => state.question.errorCode
  );

  // Chat State
  const chats = useSelector((state: RootState) => state.chat.chats);
  const errorChat = useSelector((state: RootState) => state.chat.error);
  const errorCodeChat = useSelector((state: RootState) => state.chat.errorCode);

  const isLoading = useSelector((state: RootState) => state.question.fetching);

  // Auth state
  // const isAuthenticated = useSelector(
  //   (state: RootState) => state.auth.isAuthenticated
  // );
  const errorAuth = useSelector((state: RootState) => state.auth.error);
  const errorCodeAuth = useSelector((state: RootState) => state.auth.errorCode);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken); // Update the state with the fetched token
      } catch (error) {
        console.error("Error fetching access token:", error);
        setToken(""); // Handle errors (e.g., by setting token to null)
      }
    };
    fetchToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (token) {
      dispatch(fetchChats(chatPage, token));
    }
  }, [dispatch, token]);

  const [focusGroupSelected, setFocusGroupSelected] = useState<boolean>(false);
  const [surveySelected, setSurveySelected] = useState<boolean>(false);

  // Keep tabs of page count
  const [questionPage, setQuestionPage] = useState<number>(1);
  const [chatPage, setChatPage] = useState<number>(1);

  const lastElementRef = useRef<Element | null>(null);

  const handleScroll = debounce(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight - 100 // Trigger a bit before reaching the bottom
    ) {
      setQuestionPage((prevPage) => prevPage + 1);
      setChatPage((prevPage) => prevPage + 1);
    }
  }, 100); // Debounce the scroll event

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (token) {
      dispatch(fetchChats(chatPage, token));
    }
  }, [questionPage, chatPage, dispatch, token]);

  useEffect(() => {
    console.log("Error code: ", errorCodeAuth);
    if (
      [errorCodeQuestion, errorCodeChat, errorCodeAuth].some(
        (code) => code === 401
      )
    ) {
      // Perform your operations here
      console.log("Error 401 detected");
      // dispatch(logout());
      navigate("/");
    }
  }, [errorCodeQuestion, errorCodeChat, errorCodeAuth]);

  const combinedItems: CombinedItemType[] = useMemo(() => {
    // Provide an empty array as a fallback if questions or chats are null
    const questionsArray = questions || [];
    const chatsArray = chats || [];

    return [...questionsArray, ...chatsArray].sort((a, b) => {
      const dateA = a.updated_at ? new Date(a.updated_at).getTime() : 0;
      const dateB = b.updated_at ? new Date(b.updated_at).getTime() : 0;
      return dateB - dateA; // Sorts in descending order
    });
  }, [questions, chats]);

  const filteredItems: CombinedItemType[] = useMemo(() => {
    // Show all items if none of the tags are selected
    if (!focusGroupSelected && !surveySelected) {
      return combinedItems;
    }

    return combinedItems.filter((item) => {
      if ("messages" in item) {
        // Assuming ChatData items are related to "Focus Group"
        return focusGroupSelected;
      } else {
        // Assuming QuestionData items are related to "Survey"
        return surveySelected;
      }
    });
  }, [combinedItems, focusGroupSelected, surveySelected]);

  return (
    <Container size="xl" mt={20} mb={20}>
      {/* <InputWithButton size="md" /> */}
      {/* <Box mb="md">
        <SelectableTag
          label="Focus Group"
          selected={focusGroupSelected}
          toggleSelect={() => setFocusGroupSelected(!focusGroupSelected)}
        />
        <SelectableTag
          label="Survey"
          selected={surveySelected}
          toggleSelect={() => setSurveySelected(!surveySelected)}
        />
      </Box> */}
      {isLoading ? <Loader color="blue" /> : null}
      {filteredItems.length > 0 ? (
        <Grid
          className="your-list-element-class"
          grow
          gutter="md"
          style={{ display: "flex", alignItems: "stretch" }}
        >
          {filteredItems.map((item, index) => {
            const isLastItem = index === filteredItems.length - 1; // Check if it's the last item
            const itemClass = isLastItem ? "last-item-class" : ""; // Class for the last item

            if ("messages" in item) {
              const questionItem = item as ChatDataType;
              // Assuming chat items have 'messages'
              return (
                <Col
                  span={12}
                  xs={6}
                  md={4}
                  lg={4}
                  style={{ display: "flex" }}
                  key={questionItem.id}
                  className={itemClass} // Add the class here
                >
                  <ChatPreview
                    key={questionItem.id}
                    question={
                      questionItem.messages.length > 1
                        ? questionItem.messages[0].answer
                        : "N/A"
                    }
                    // question="cat"
                    agents={questionItem.agent_id}
                    id={questionItem.id ? questionItem.id : false}
                  />
                </Col>
              );
            } else {
              // Assuming question items don't have 'messages'
              const questionItem = item as QuestionDataType;
              return (
                <Col
                  span={12}
                  xs={6}
                  md={4}
                  lg={4}
                  style={{ display: "flex" }}
                  key={questionItem.id}
                  className={itemClass} // Add the class here
                >
                  <SurveyPreview
                    key={questionItem.id}
                    question={questionItem.question}
                    options={questionItem.answers.join(", ")}
                    confidence="Medium"
                    demographics={questionItem.demographics.join(", ")}
                    published={questionItem.published}
                    id={questionItem.id ? questionItem.id : false}
                  />
                </Col>
              );
            }
          })}
        </Grid>
      ) : null}
      {chats && chats.length == 0 ? (
        <div>
          <Alert variant="light" color="yellow" title="" icon={icon} mt={10}>
            No chats found.
            <Text
              onClick={() => navigate("/chat/new-chat")}
              style={{ color: "purple", cursor: "pointer" }}
              size="sm"
            >
              Create your first survey chat now!
            </Text>
          </Alert>
        </div>
      ) : null}
    </Container>
  );
}
