import React from "react";
import {
  TextInput,
  TextInputProps,
  ActionIcon,
  useMantineTheme,
  Box,
} from "@mantine/core";
import { IconSearch, IconArrowRight, IconArrowLeft } from "@tabler/icons-react";

interface InputWithButtonProps extends Omit<TextInputProps, "onChange"> {
  value: string;
  onSearch: (value: string, doSearch: boolean) => void; // Changed from onValueChange to onSearch
}

export function InputWithButton({
  value,
  onSearch,
  ...props
}: InputWithButtonProps) {
  const theme = useMantineTheme();

  // Function to call when search needs to be triggered
  const handleSearch = () => {
    onSearch(value, true); // Use the current input value to perform the search
  };

  // Handle key press in the input to detect Enter key
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch(); // Trigger search on Enter key
    }
  };

  return (
    <Box mb="sm">
      <TextInput
        icon={<IconSearch size="1.1rem" stroke={1.5} />}
        radius="xl"
        size="md"
        value={value}
        onChange={(event) => onSearch(event.target.value, false)} // Handles direct input changes
        onKeyPress={handleKeyPress} // Add key press handler for detecting Enter key
        rightSection={
          <ActionIcon
            size={32}
            radius="xl"
            color={theme.primaryColor}
            variant="filled"
            onClick={handleSearch} // Add onClick handler to the search icon
          >
            {theme.dir === "ltr" ? (
              <IconArrowRight size="1.1rem" stroke={1.5} />
            ) : (
              <IconArrowLeft size="1.1rem" stroke={1.5} />
            )}
          </ActionIcon>
        }
        placeholder="Search Insights"
        rightSectionWidth={42}
        {...props} // Spread any additional TextInputProps
      />
    </Box>
  );
}
