import React, { useEffect, useState } from "react";
import { Card, Grid, Divider, Col, Container } from "@mantine/core";
import { useParams } from "react-router-dom";
import { notifications } from "@mantine/notifications";

import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import SurveyForm from "../components/SurveyForm";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState, AppThunk } from "../redux/store"; // Adjust the path as necessary
import { fetchQuestionById } from "../redux/actions/questionActions";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/actions/authActions";

interface DataDistribution {
  [demographic: string]: number;
}

type DataOutput = {
  name: string;
} & DataDistribution;

function generateRandomData(
  answers: string[],
  demographics: string[]
): DataOutput[] {
  return answers.map((answer) => {
    let remaining = 100;
    const distribution: DataDistribution = demographics.reduce(
      (acc, demographic, idx) => {
        let value;

        // For the last demographic, we simply assign whatever is left of the 250 to ensure total is exactly 250.
        if (idx === demographics.length - 1) {
          value = remaining;
        } else {
          // Randomly distribute among demographics, but ensure there's some remaining for the next demographics.
          value = Math.floor(
            Math.random() * (remaining - demographics.length + idx + 1)
          );
          remaining -= value;
        }

        acc[demographic] = value;
        return acc;
      },
      {} as DataDistribution
    );

    return { name: answer, ...distribution } as DataOutput;
  });
}
const colors = [
  "#eb5250", // Red
  "#6096c4", // Blue
  "#82ca9d", // Green
  "#ffb347", // Orange
  "#8884d8", // Purple
  "#a4de6c", // Light Green
  "#d0ed57", // Yellow Green
  "#ffc658", // Yellow
  "#70c1b3", // Teal
  "#247ba0", // Dark Blue
];

export function SurveyAnswers() {
  const { questionId } = useParams();
  const navigate = useNavigate();
  const dispatch =
    useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const defaultAnswers: string[] = [];
  const defaultDemographics: string[] = [];
  const [chartData, setChartData] = useState<DataOutput[]>([]);
  const questionData = useSelector((state: RootState) => state.question);

  useEffect(() => {
    if (questionId) {
      dispatch(fetchQuestionById(questionId));
    }
  }, [questionId, dispatch]);

  useEffect(() => {
    if (questionData.presaved) {
      const question = questionData.presaved;
    }
    if (questionData.errorCode === 401) {
      dispatch(logout());
      navigate("/");
    }
    if (questionData.errorCode) {
      notifications.show({
        title: "Error",
        message: questionData.error,
        color: "red",
      });
    }
  }, [questionData]);

  useEffect(() => {
    const answers = questionData.presaved?.answers || defaultAnswers;
    const demographics =
      questionData.presaved?.demographics || defaultDemographics;
    setChartData(generateRandomData(answers, demographics));
  }, [questionData]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on component mount
  }, []);
  return (
    <Grid>
      <Col span={8}>
        <ResponsiveContainer width="100%" height={700}>
          <BarChart
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" fill="#f5f2f2" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {(questionData.presaved?.demographics || []).map((demo, index) => (
              <Bar
                key={demo}
                dataKey={demo}
                fill={colors[index % colors.length]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Col>
      <Col span={4}>
        <SurveyForm
          initialQuestion={questionData.presaved?.question}
          initialOptions={questionData.presaved?.answers}
          initialDemographics={questionData.presaved?.demographics}
        />
      </Col>
    </Grid>
  );
}
