import { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState, AppThunk } from "../redux/store"; // Adjust the path as necessary
import { useWebSocketContext } from "../providers/WebSocketProvider";
import { fetchChatById } from "../redux/actions/chatActions";

// import useAutoResizeTextArea from "../hooks/useAutoResizeTextArea";
import Message from "./Message";
import { DEFAULT_OPENAI_MODEL } from "../shared/Constants";
import "../styles/chat/Message.css";
import {
  Loader,
  Grid,
  ActionIcon,
  TextInput,
  TextInputProps,
  useMantineTheme,
  Textarea,
  rem,
  ScrollArea,
  Text,
  Box,
} from "@mantine/core";
import { IconSearch, IconArrowRight } from "@tabler/icons-react";

const Chat = (props: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");
  const { toggleComponentVisibility, chatData } = props;
  const { isConnected, sendMessage: sendWebSocketMessage } =
    useWebSocketContext();
  const dispatch =
    useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const [numAgents, setNumAgents] = useState(0);
  const [initialLength, setInitialLength] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showEmptyChat, setShowEmptyChat] = useState(true);
  const [conversation, setConversation] = useState<any[]>([]);
  const [message, setMessage] = useState("");
  const [isPolling, setIsPolling] = useState(false);
  const bottomOfChatRef = useRef<HTMLDivElement>(null);
  const selectedModel = DEFAULT_OPENAI_MODEL;

  const theme = useMantineTheme();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken); // Update the state with the fetched token
      } catch (error) {
        console.error("Error fetching access token:", error);
        setToken(""); // Handle errors (e.g., by setting token to null)
      }
    };

    fetchToken();
  }, [token, getAccessTokenSilently]);

  const scrollToBottom = () => {
    const scrollElement = bottomOfChatRef.current;
    if (scrollElement) {
      scrollElement!.scrollTo({
        top: scrollElement.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!isPolling) {
      scrollToBottom();
    }
  }, [conversation]);

  const fetchedChat = useSelector((state: RootState) => state.chat.presaved);

  useEffect(() => {
    if (fetchedChat?.messages && isPolling) {
      setConversation(fetchedChat.messages.map((msg: any) => ({ ...msg })));
      // Implement your check here
      if (conversation.length >= initialLength + numAgents + 1 && isPolling) {
        setIsPolling(false);
        setIsLoading(false);
      }
    }
  }, [fetchedChat, conversation, numAgents]);

  useEffect(() => {
    let intervalId: any;

    if (isPolling) {
      intervalId = setInterval(() => {
        dispatch(fetchChatById(chatData.presaved.id, token));
      }, 1000); // Adjust the timing as needed
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isPolling, dispatch, chatData.presaved.id]);

  const submitMessage = async (e: any) => {
    e.preventDefault();
    setNumAgents(chatData.presaved.agent_id.length);
    setIsPolling(true); // Start polling
    // Don't send empty messages
    if (message.length < 1) {
      setErrorMessage("Please enter a message.");
      return;
    } else {
      setErrorMessage("");
    }

    setIsLoading(true);

    // Add the message to the conversation
    setConversation((convo) => [
      ...convo,
      { answer: message, role: "user", sources: [], plugin_id: "0" },
    ]);
    setInitialLength(conversation.length);

    // Clear the message & remove empty chat
    setMessage("");
    setShowEmptyChat(false);

    try {
      // Send the message through the WebSocket connection

      // Iterate over the agent_id array to create an array of objects
      const messagesToSend = chatData.presaved.agent_id.map(
        (agent: any, index: number) => ({
          query: message,
          role: "user",
          agent_id: agent.plugin_id,
          user_id: agent.id,
          client_id: "moshe",
          chat_id: chatData.presaved.id,
          save: index === 0, // true for the first element, false for the rest
        })
      );

      // Send the array of objects as a single message
      // await dispatch(sendMessage({ messages: messagesToSend }));
      sendWebSocketMessage({ messages: messagesToSend });
    } catch (error: any) {
      console.error(error);
      setErrorMessage(error.message);
    }
  };

  const handleKeypress = (e: any) => {
    // It triggers by pressing the enter key
    if (e.keyCode === 13 && !e.shiftKey) {
      submitMessage(e);
      e.preventDefault();
    }
  };

  useEffect(() => {
    // Check if presaved messages exist in chatData
    if (
      chatData &&
      chatData.presaved &&
      chatData.presaved.messages &&
      chatData.presaved.messages.length > 0
    ) {
      setShowEmptyChat(false);
      setConversation(
        chatData.presaved.messages.map((msg: any) => ({
          ...msg,
        }))
      );
    }
  }, [chatData]); // Run this effect when chatData changes
  const findAgent = (pluginId: string | string[], agents: any[]) => {
    for (let agent of agents) {
      for (let plugin of agent.plugin_id) {
        if (Array.isArray(pluginId)) {
          if (pluginId.includes(plugin.plugin_id)) {
            return agent;
          }
        } else {
          if (plugin.plugin_id === pluginId) {
            return agent;
          }
        }
      }
    }
    return null; // Return null if no matching agent is found
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          position: "relative",
          height: "calc(80vh)",
        }}
      >
        <ScrollArea
          viewportRef={bottomOfChatRef}
          style={{ height: "calc(100% - 60px)" }}
          type="scroll"
        >
          {/* Content for the second section goes here */}
          <div
            className="react-scroll-to-bottom--css-ikyem-1n7m0yu"
            style={{ margin: "0px 10px" }}
          >
            {!showEmptyChat && conversation.length > 0 ? (
              <div
                className="flex flex-col items-center text-sm bg-white"
                style={{ padding: "0px 10px" }}
              >
                {conversation.map((message, index) => {
                  const agent = findAgent(
                    message.plugin_id,
                    chatData.presaved.agent_id
                  );
                  return (
                    <Message
                      agent={agent}
                      key={index}
                      message={message}
                      scrollToBottom={scrollToBottom}
                      isLastMessage={index === conversation.length - 1}
                    />
                  );
                })}
                {isLoading ? <Loader color="blue" type="dots" mt={30} /> : null}
                <div className="w-full h-32 md:h-48 flex-shrink-0"></div>
              </div>
            ) : null}
            {showEmptyChat ? (
              <Text
                style={{ textAlign: "center" }}
                fs="italic"
                size="sm"
                mt={10}
              >
                The beginning of your conversation, type in your question!
              </Text>
            ) : null}
            <div className="flex flex-col items-center text-sm dark:bg-gray-800"></div>
          </div>
          <div className="anchor-to" ref={bottomOfChatRef}></div>
        </ScrollArea>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        >
          <div style={{ margin: "0px 10px" }}>
            <Textarea
              size="md"
              value={message}
              placeholder="Search questions"
              rightSectionWidth={42}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={handleKeypress}
              autosize
              style={{
                maxHeight: "200px",
                overflowY: "auto",
                position: "relative",
              }}
            />
            <ActionIcon
              size={32}
              style={{
                position: "absolute",
                bottom: 8, // Adjust as needed
                right: 16, // Adjust as needed
                zIndex: 1,
              }}
              color={theme.primaryColor}
              variant="filled"
              disabled={isLoading || message?.length === 0}
              onClick={submitMessage}
            >
              <IconArrowRight
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
              />
            </ActionIcon>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
