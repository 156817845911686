import {
  AUTH0_ROLES_KEY,
  CIVICSYNC_ADMIN,
  CIVICSYNC_CUSTOMER,
} from "../config";
import React, { useState, useEffect } from "react";
import {
  createStyles,
  Title,
  Text,
  Button,
  Container,
  rem,
  Divider,
  Loader,
  Skeleton,
} from "@mantine/core";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Dots } from "./Dots";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store"; // Adjust the path as necessary
import { googleAuth } from "../redux/actions/authActions";
import { jwtDecode } from "jwt-decode"; // Correct the import

import { StepsCreateIdentifier } from "./StepsCreateIdentifier";

// Define a custom interface that extends JwtPayload
interface CustomJwtPayload {
  [key: string]: any; // Allow any string keys
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(250),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },

    "::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      opacity: 1,
      zIndex: 0,
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
  },

  dots: {
    position: "absolute",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[1],

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  dotsLeft: {
    left: 0,
    top: 0,
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      textAlign: "left",
    },
  },

  highlight: {
    color:
      theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },

  description: {
    textAlign: "center",

    [theme.fn.smallerThan("xs")]: {
      textAlign: "left",
      fontSize: theme.fontSizes.md,
    },
  },

  controls: {
    marginTop: theme.spacing.lg,
    display: "flex",
    justifyContent: "center",

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  control: {
    "&:not(:first-of-type)": {
      marginLeft: theme.spacing.md,
    },

    [theme.fn.smallerThan("xs")]: {
      height: rem(42),
      fontSize: theme.fontSizes.md,

      "&:not(:first-of-type)": {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },
}));

export function HeroText() {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const userCivicSync = useSelector((state: RootState) => state.auth.userData);
  const loading = useSelector((state: RootState) => state.auth.loading);
  const [accessToken, setAccessToken] = useState("");
  const [roles, setRoles] = useState<string[]>([]); // Ensure roles is typed as string[]

  const dispatch =
    useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decodedToken = jwtDecode<CustomJwtPayload>(token);
        const roles = decodedToken[AUTH0_ROLES_KEY] || [];
        setRoles(roles);
        dispatch(googleAuth(token));
        setAccessToken(token);
      } catch (e) {
        console.error(e);
      }
    };
    if (isAuthenticated) {
      getToken();
    }
  }, [isAuthenticated, getAccessTokenSilently, dispatch]);

  const auth = useSelector((state: RootState) => state.auth);

  const renderSkeleton = () => {
    return (
      <Container p={0} size={600}>
        <Skeleton height={25} radius="xl" />
        <Skeleton height={25} mt={6} radius="xl" />
        <Skeleton height={25} mt={6} width="70%" radius="xl" />
      </Container>
    );
  };

  const renderContent = () => {
    if (loading || isLoading) {
      return renderSkeleton();
    }
    else if (!isAuthenticated) {
      return (
        <>
          <Container p={0} size={600}>
            <Text size="lg" color="dimmed" className={classes.description}>
              Create your digital replica in less than 5 minutes!
            </Text>
            <div className={classes.controls}>
              <Button
                className={classes.control}
                size="lg"
                variant="default"
                color="gray"
                onClick={() => loginWithRedirect()}
              >
                Create my account
              </Button>
            </div>
          </Container>
        </>
      );
    }
    else if (roles.includes(CIVICSYNC_ADMIN) || roles.includes(CIVICSYNC_CUSTOMER)) {
      return (
        <>
          <Container p={0} size={600}>
            <Text size="lg" color="dimmed" className={classes.description}>
              With CivicSync you can create your survey, choose your audience,
              and get results in minutes.
            </Text>
          </Container>
          <div className={classes.controls}>
            <Button
              className={classes.control}
              size="lg"
              variant="default"
              color="gray"
              onClick={() => navigate("/past-surveys")}
            >
              View Past Surveys and Focus Groups
            </Button>
            <Button
              onClick={() => navigate("/chat/new-chat")}
              className={classes.control}
              size="lg"
            >
              Create New Focus Group
            </Button>
          </div>
        </>
      );
    } else if (userCivicSync?.vpn_active) {
      return (
        <>
          <Container p={0} size={800}>
            <Text
              size="lg"
              color="dimmed"
              className={classes.description}
              mb={20}
            >
              Start conversing with you digital replica now.
            </Text>
            <div className={classes.controls}>
              <Button
                onClick={() => navigate("/chat/me")}
                className={classes.control}
                size="lg"
              >
                Go to chat!
              </Button>
            </div>
          </Container>
        </>
      );
    } else {
      return (
        <>
          <Container p={0} size={800}>
            <Text
              size="lg"
              color="dimmed"
              className={classes.description}
              mb={20}
            >
              Start conversing with your digital replica today. Follow the
              following steps to create your digital replica.
            </Text>
            <StepsCreateIdentifier />
          </Container>
        </>
      );
    }
  };

  return (
    <Container className={classes.wrapper} size={"100%"}>
      <Dots className={`${classes.dots} ${classes.dotsLeft}`} />
      <Dots className={classes.dots} style={{ left: 60, top: 0 }} />
      <Dots className={classes.dots} style={{ left: 0, top: 140 }} />
      <Dots className={classes.dots} style={{ right: 0, top: 60 }} />

      <div className={classes.inner}>
        <Title className={classes.title}>
          <Text component="span" className={classes.highlight} inherit>
            Hello,
          </Text>{" "}
          Welcome to CivicSync
        </Title>
        {renderContent()}
      </div>
    </Container>
  );
}
