import React from "react";
import { Alert, Container, Button, Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";

interface ErrorMessageProps {
  text?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ text }) => {
  const navigate = useNavigate();

  if (!text) return null;

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <Container>
      <Alert mt={20} title="Error" color="red" variant="light">
        {text}
        <Group mt="md" mb="md">
          <Button onClick={handleGoHome} variant="outline" color="red">
            Go back home
          </Button>
        </Group>
      </Alert>
    </Container>
  );
};

export default ErrorMessage;

