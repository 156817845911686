import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Chat from "../components/Chat";
import ErrorMessage from "../components/ErrorMessage";
import Sidebar from "../components/Sidebar";
import { useDisclosure } from "@mantine/hooks";
import { Grid, rem, Text, Drawer, createStyles, Box } from "@mantine/core";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState, AppThunk } from "../redux/store"; // Adjust the path as necessary
import { BASE_API_URL } from "../config";

import { fetchChatById } from "../redux/actions/chatActions";

const useStyles = createStyles((theme) => ({
  hiddenTablet: {
    [theme.fn.smallerThan("lg")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("lg")]: {
      display: "none",
    },
  },
}));

export function HomeChat() {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");
  const { chatId } = useParams();
  let newChatId = chatId;
  const { chatId: paramChatId } = useParams<{ chatId: string }>();
  const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] =
    useDisclosure(false);
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  // const theme = useMantineTheme();
  const { classes, theme } = useStyles();
  const dispatch =
    useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const isQuestionSaved = useSelector(
    (state: RootState) => state.question.saved
  );
  const chatData = useSelector((state: RootState) => state.chat);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (!paramChatId) return; // Ensure paramChatId is defined

    const fetchCorrectChatId = async () => {
      try {
        const response = await axios.get(`${BASE_API_URL}/chat/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data.data[0].id; // Assuming the response contains the correct chatId
      } catch (error) {
        console.error("Error fetching correct chat ID:", error);
        return null;
      }
    };

    const fetchChatData = async () => {
      if (paramChatId === "me") {
        const correctChatId = await fetchCorrectChatId();
        if (correctChatId) {
          dispatch(fetchChatById(correctChatId, token));
          setDataLoaded(false);
        }
      } else {
        dispatch(fetchChatById(paramChatId, token));
        setDataLoaded(false);
      }
    };

    if (token) {
      fetchChatData();
    }
  }, [paramChatId, token, dispatch]);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken); // Update the state with the fetched token
      } catch (error) {
        console.error("Error fetching access token:", error);
        setToken(""); // Handle errors (e.g., by setting token to null)
      }
    };

    fetchToken();
  }, [token, getAccessTokenSilently]);

  useEffect(() => {
    if (chatData.presaved) {
      const chat = chatData.presaved;
      setDataLoaded(true); // this will ensure the form isn't updated in subsequent renders
    }
  }, [chatData]);

  const toggleComponentVisibility = () => {
    setIsComponentVisible(!isComponentVisible);
  };

  return (
    <div>
      {chatData.error && (
        <ErrorMessage text={chatData.error ? chatData.error : ""} />
      )}

      <Box
        style={{
          backgroundColor: "#e7f5ff",
        }}
        sx={(theme) => ({
          boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)", // Adjust the values as needed
        })}
        p={10}
        className={classes.hiddenDesktop}
      >
        <Text
          onClick={toggleDrawer}
          style={{
            color: "#238be7",
            cursor: "pointer",
          }}
          size={15}
        >
          Chat Participants
        </Text>
      </Box>
      <Drawer
        opened={drawerOpened}
        onClose={closeDrawer}
        size="100%"
        padding="md"
        title="Chat Participants"
        className={classes.hiddenDesktop}
        zIndex={10000000}
      >
        <Sidebar chatParticipents={chatData.presaved?.agent_id || []} />
      </Drawer>
      {dataLoaded ? (
        <>
          <Grid>
            <Grid.Col
              lg={2}
              style={{ borderRight: "1px solid rgb(233, 236, 239)" }}
              className={classes.hiddenTablet}
            >
              <Sidebar chatParticipents={chatData.presaved?.agent_id || []} />
            </Grid.Col>
            <Grid.Col
              lg={10}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Chat
                chatData={chatData}
                toggleComponentVisibility={toggleComponentVisibility}
              />
            </Grid.Col>
          </Grid>
        </>
      ) : null}
    </div>
  );
}
