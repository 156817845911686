import React from "react";
import {
  Paper,
  Text,
  Card,
  Image,
  ScrollArea,
  Badge,
  Group,
} from "@mantine/core";

import "../styles/chat/Source.css";

interface Source {
  text: string;
  url: string;
  summary?: string;
}

interface SourceDisplayProps {
  sources: Source[];
}
export const getDomainFromUrl = (url: string): string => {
  try {
    const newUrl = new URL(url);
    return newUrl.hostname;
  } catch (error) {
    console.error("Invalid URL", error);
    return "";
  }
};
const SourceDisplay: React.FC<SourceDisplayProps> = ({ sources }) => {
  console.log(sources);
  return (
    <ScrollArea
      scrollbarSize={0}
      className="scroll"
      style={{ width: "100%", height: "100%" }}
    >
      <div
        className="scroll"
        style={{ display: "flex", overflowX: "auto", padding: "1rem 0" }}
      >
        {sources.map((source) => (
          <Card
            key={source.url}
            shadow="sm"
            padding="lg"
            component="a"
            href={source.url}
            target="_blank"
            style={{
              border: "1px #D3D3D3 solid",
              textDecoration: "none",
              width: "300px",
              marginRight: "1rem",
              height: "200px", // Increase height to show more text
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Group noWrap align="center">
              <Text
                style={{
                  flex: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                c="dimmed"
                fw={500}
                size="sm"
              >
                {source.text}
              </Text>
            </Group>
            <Text
              style={{
                marginTop: "0.5rem",
                flex: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "normal", // Allow text to wrap
              }}
              size="sm"
            >
              {source.summary && source.summary.length > 100
                ? `${source.summary.substring(0, 100)}...`
                : source.summary || source.text}
            </Text>
            <Group noWrap spacing="xs" style={{ marginTop: "0.5rem" }}>
              <Image
                src={`http://www.google.com/s2/favicons?domain=${source.url}`}
                alt={`${source.url} favicon`}
                height={15}
                width={15}
                fit="contain"
                style={{ borderRadius: "50%" }} // Make the image circular
              />
              <Badge color="gray" size="xs">
                {getDomainFromUrl(source.url)
                  ? getDomainFromUrl(source.url)
                  : source.url}
              </Badge>
            </Group>
          </Card>
        ))}
      </div>
    </ScrollArea>
  );
};

export default SourceDisplay;
