import { SiOpenai } from "react-icons/si";
import { HiUser } from "react-icons/hi";
import { TbCursorText } from "react-icons/tb";
import ReactMarkdown from "react-markdown";
import { Alert, Button, Divider } from "@mantine/core";
import { IconInfoCircle, IconX } from "@tabler/icons-react";
import { Avatar } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import SourceDisplay from "./SourceDisplay";
import { Group, Box, Text, createStyles } from "@mantine/core";
import {
  IconCircleCheck,
  IconQuote,
  IconExternalLink,
} from "@tabler/icons-react";
import { ActionIcon } from "@mantine/core";

import React, { useState } from "react";

import "../styles/chat/Message.css";

function hashStringToNumber(inputString: string) {
  let hash = 0;

  for (let i = 0; i < inputString.length; i++) {
    hash += inputString.charCodeAt(i);
  }

  return hash % 6; // Modulo 7 to get a number between 0 and 6
}

const Message = (props: any) => {
  const navigate = useNavigate();
  const { message, scrollToBottom, isLastMessage, agent } = props;

  const { role, answer: text, sources } = message;

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore((prev) => !prev);
    if (isLastMessage) {
      scrollToBottom(); // Call scrollToBottom only for the last message
    }
  };

  const useStyles = createStyles((theme) => ({
    widthSources: {
      width: "80vw",
      [theme.fn.smallerThan("lg")]: {
        width: "90vw",
      },
    },
  }));

  const { classes, theme } = useStyles();

  const isUser = role === "user";
  const icon = <IconInfoCircle />;

  return (
    <Box mt={20}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {!isUser && (
          <Group spacing="xs">
            <Avatar
              src={
                "https://api.dicebear.com/8.x/lorelei-neutral/svg?backgroundColor=b6e3f4,c0aede,d1d4f9&seed=" +
                agent?.fullname
              }
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Text
                fz="md"
                align="center"
                fw={500}
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/insights/" + agent?.id)}
              >
                {agent?.fullname}
              </Text>
            </div>
          </Group>
        )}

        <div
          style={{
            flex: 1,
            minWidth: 0,
            marginTop: "10px",
            backgroundColor: isUser ? "#F2F2F2" : "transparent",
            padding: isUser ? "5px 20px" : "auto",
            borderRadius: isUser ? "20px" : "auto",
            marginLeft: isUser ? "auto" : "0", // Conditional alignment
          }}
        >
          {" "}
          {/* Flex item for text */}
          {!isUser && text === null ? (
            <TbCursorText className="h-6 w-6 animate-pulse" />
          ) : (
            <p
              style={{
                wordWrap: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
              }}
            >
              <ReactMarkdown>{text}</ReactMarkdown>
              {!isUser ? (
                <ActionIcon
                  variant="light"
                  color="gray"
                  aria-label="more info"
                  onClick={toggleShowMore}
                >
                  <IconQuote
                    style={{ width: "70%", height: "70%" }}
                    stroke={1.5}
                  />
                </ActionIcon>
              ) : null}
            </p>
          )}
          {sources && sources.length > 0 && (
            <div
              className={classes.widthSources}
              style={{
                // backgroundColor: "#F2F2F2",
                padding: "10px",
                overflowY: "auto",
                whiteSpace: "nowrap",
              }}
            >
              <SourceDisplay sources={sources} />
            </div>
          )}
          {showMore && (
            <>
              <ActionIcon
                variant="light"
                color="gray"
                style={{ position: "absolute", top: 10, right: 10 }}
                aria-label="Close"
                onClick={toggleShowMore}
              >
                <IconX style={{ width: "70%", height: "70%" }} stroke={1.5} />
              </ActionIcon>
              <div
                style={{
                  overflowX: "auto",
                  overflowY: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {/* Additional content goes here */}
              </div>
            </>
          )}
        </div>
      </div>
    </Box>
  );
};

export default Message;
