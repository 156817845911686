import { useState, useEffect } from "react";
import {
  Button,
  Group,
  Container,
  Text,
  Badge,
  Card,
  Center,
  Loader,
  Grid,
  Alert,
  ActionIcon,
  createStyles,
} from "@mantine/core";
import { IconThumbUp, IconThumbDown, IconActivity } from "@tabler/icons-react";
import { useAuth0 } from "@auth0/auth0-react";
import ReactMarkdown from "react-markdown";
import { formatDistanceToNow } from "date-fns";
import { UserInfoCard } from "../components/UserInfoCard";
import { InputWithButton } from "../components/InputWithButton";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../redux/store";
import { fetchInsights } from "../redux/actions/insightActions";

function formatTimestamp(timestamp: string) {
  const zuluDate = new Date(timestamp); // Parse the Zulu (UTC) timestamp
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const localDate = new Date(
    zuluDate.toLocaleString("en-US", { timeZone: userTimeZone })
  );

  // Format the date using date-fns
  return formatDistanceToNow(localDate, { addSuffix: true });
}

const useStyles = createStyles((theme) => ({
  hiddenTablet: {
    [theme.fn.smallerThan("lg")]: {
      display: "none",
    },
  },

  hiddenDesktop: {
    [theme.fn.largerThan("lg")]: {
      display: "none",
    },
  },
}));

export function Insights() {
  const { classes, theme } = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState<string | null>(null);
  const { agentId } = useParams();
  const navigate = useNavigate();
  const dispatch =
    useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const insightData = useSelector((state: RootState) => state.insight);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasFetchedInitialData, setHasFetchedInitialData] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken); // Update the state with the fetched token
      } catch (error) {
        console.error("Error fetching access token:", error);
        setToken(null); // Handle errors (e.g., by setting token to null)
      }
    };
    fetchToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (agentId && token && !hasFetchedInitialData) {
      fetchMoreInsights(true, "");
      setHasFetchedInitialData(true); // Set this to true after initial fetch
    }

    function handleScroll() {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;
      if (bottom && !isLoading) {
        fetchMoreInsights();
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [agentId, isLoading, token]);

  function fetchMoreInsights(force = false, newSearchTerm = "") {
    const termToUse = force ? newSearchTerm : searchTerm; // Use newSearchTerm if forced, else current searchTerm
    if (!isLoading && agentId && token) {
      setIsLoading(true);
      const nextPage = force ? 1 : page + 1; // If force is true, reset to 1, otherwise increment
      dispatch(fetchInsights(agentId, nextPage, termToUse, force, token))
        .then(() => {
          setPage(nextPage); // Set the page after fetching
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Failed to fetch insights:", error);
          setIsLoading(false);
        });
    }
  }

  function getColorForEntityGroup(entityGroup: string): string {
    const entityColorMapping: { [key: string]: string | undefined } = {
      LOC: "blue", // Locations
      PER: "red", // Persons
      ORG: "green", // Organizations
      MISC: "grape", // Miscellaneous
    };
    return entityColorMapping[entityGroup] || "gray"; // Default to "gray" if the key is not found
  }

  function toggleExpand(index: number) {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  }

  function RenderEntities(insights: any) {
    // Extracting unique entities with their types
    const getUniqueEntities = (insights: any) => {
      const entities = new Map(); // Using a Map to ensure uniqueness and store additional data

      insights.insights.forEach((insight: any) => {
        const ners = insight?.ner || [];
        ners
          .filter((ner: any) => ner.score > 0.7)
          .forEach((entity: any) => {
            const key = `${entity.word}_${entity.entity_group}`; // Unique key combining word and type
            if (!entities.has(key)) {
              // Check if the entity is already added
              entities.set(key, {
                word: entity.word,
                entityGroup: entity.entity_group,
              });
            }
          });
      });

      return Array.from(entities.values()); // Convert the map values to an array for rendering
    };

    const uniqueEntities = getUniqueEntities(insights);

    return (
      <Group pt={20}>
        {uniqueEntities.map((entity, index) => (
          <Badge
            key={index}
            color={getColorForEntityGroup(entity.entityGroup)}
            size="lg"
          >
            {entity.word}
          </Badge>
        ))}
      </Group>
    );
  }

  return (
    <Container size={"100wh"}>
      <div
        className={classes.hiddenDesktop}
        style={{
          position: "sticky",
          top: 20,
          zIndex: 100000,
          padding: "20px",
        }}
      >
        <InputWithButton
          value={searchTerm}
          onSearch={(value, doSearch: boolean) => {
            setSearchTerm(value); // Optional: update the searchTerm state
            if (doSearch) {
              fetchMoreInsights(true, value); // Force new search and reset page
            }
          }}
          mt={20}
        />
      </div>
      <Grid>
        <Grid.Col
          className={classes.hiddenTablet}
          sm={12}
          lg={2}
          style={{ height: "100vh", position: "sticky", top: 0 }}
        >
          <InputWithButton
            value={searchTerm}
            onSearch={(value, doSearch: boolean) => {
              setSearchTerm(value); // Optional: update the searchTerm state
              if (doSearch) {
                fetchMoreInsights(true, value); // Force new search and reset page
              }
            }}
            mt={20}
          />

          <UserInfoCard />
        </Grid.Col>
        <Grid.Col sm={12} lg={10}>
          <div className={classes.hiddenDesktop}>
            <UserInfoCard />
          </div>
          {insightData.insights?.map((insight, index) => (
            <Card
              key={index}
              mt={20}
              shadow="sm"
              padding="lg"
              radius="md"
              withBorder
            >
              <Card.Section withBorder inheritPadding py="xs">
                <Text color="dimmed" size="sm">
                  {formatTimestamp(insight["_source"].timestamp)}
                </Text>
              </Card.Section>
              <Card.Section withBorder inheritPadding py="xs">
                <Text>
                  <ReactMarkdown>{insight["_source"].summary}</ReactMarkdown>
                </Text>

                <RenderEntities insights={insight["_source"].summaries} />
                <Group
                  position="right"
                  spacing="xs"
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  <ActionIcon variant="light" color="green">
                    <IconThumbUp size="1.2rem" />
                  </ActionIcon>
                  <ActionIcon variant="light" color="red">
                    <IconThumbDown size="1.2rem" />
                  </ActionIcon>
                </Group>
              </Card.Section>
              <Card.Section withBorder inheritPadding py="xs">
                {expanded[index] &&
                  insight["_source"].summaries.map(
                    (sub_insight: any, idx: number) => (
                      <Alert
                        key={idx}
                        mt={10}
                        icon={<IconActivity size="1rem" />}
                        color="yellow"
                      >
                        <ReactMarkdown>{sub_insight.summary}</ReactMarkdown>
                      </Alert>
                    )
                  )}
                <Button
                  mt={10}
                  onClick={() => toggleExpand(index)}
                  variant="subtle"
                >
                  {expanded[index] ? "See Less" : "See More"}
                </Button>
              </Card.Section>
            </Card>
          ))}
          {isLoading && (
            <Center style={{ height: "100px" }}>
              {" "}
              {/* Adjust height as needed */}
              <Loader color="blue" size="lg" />
            </Center>
          )}
          {!isLoading && insightData.insights?.length === 0 && (
            <Alert mt={20}>No insights found</Alert>
          )}
        </Grid.Col>
      </Grid>
    </Container>
  );
}
