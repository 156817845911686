import {
  createStyles,
  Table,
  Progress,
  Anchor,
  Text,
  Group,
  ScrollArea,
  rem,
  Button,
} from "@mantine/core";
import { HiOutlineRefresh, HiOutlineTrash } from "react-icons/hi";

const useStyles = createStyles((theme) => ({
  progressBar: {
    "&:not(:first-of-type)": {
      borderLeft: `${rem(3)} solid ${
        theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
      }`,
    },
  },
}));

interface SnapshotsProps {
  data: {
    title: string;
  }[];
}

export function Snapshots({ data }: SnapshotsProps) {
  const { classes, theme } = useStyles();

  const rows = data.map((row) => {
    return (
      <tr key={row.title}>
        <td align="left">
          <Anchor component="button" fz="sm">
            {row.title}
          </Anchor>
        </td>
        <td>
          <div style={{ display: "flex", alignItems: "left" }}>
            <Button
              size="xs"
              color="teal"
              variant="light"
              leftIcon={<HiOutlineRefresh />}
              style={{ marginRight: "1rem" }}
            >
              Load
            </Button>
            <Button
              color="red"
              variant="light"
              leftIcon={<HiOutlineTrash />}
              size="xs"
            >
              Delete
            </Button>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <ScrollArea>
      <Table sx={{ minWidth: 300 }} verticalSpacing="xs">
        <thead>
          <tr>
            <th>Date and time</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </ScrollArea>
  );
}
