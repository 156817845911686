import AgentPreview from "../components/AgentPreview";
import {
  Card,
  Grid,
  Container,
  Col,
  Title,
  Divider,
  Loader,
  Alert,
  createStyles,
  Button,
  rem,
  Text,
  Box,
  Group,
  Chip,
  ActionIcon,
  Affix,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useAuth0 } from "@auth0/auth0-react";
import { IconInfoCircle, IconX } from "@tabler/icons-react";
import { InputWithButton } from "../components/InputWithButton";
import { useNavigate } from "react-router-dom";

import { logout } from "../redux/actions/authActions";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState, AppThunk } from "../redux/store"; // Adjust the path as necessary
import {
  fetchAgents,
  saveChat,
  resetChatSaved,
} from "../redux/actions/chatActions";
const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan("sm")]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: "relative",
    zIndex: 1,
  },

  dots: {
    position: "absolute",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[5]
        : theme.colors.gray[1],

    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  dotsLeft: {
    left: 0,
    top: 0,
  },

  title: {
    textAlign: "center",
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(28),
      textAlign: "left",
    },
  },

  highlight: {
    color:
      theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6],
  },

  description: {
    textAlign: "center",

    [theme.fn.smallerThan("xs")]: {
      textAlign: "left",
      fontSize: theme.fontSizes.md,
    },
  },

  controls: {
    marginTop: theme.spacing.lg,
    display: "flex",
    justifyContent: "center",

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  control: {
    "&:not(:first-of-type)": {
      marginLeft: theme.spacing.md,
    },

    [theme.fn.smallerThan("xs")]: {
      height: rem(42),
      fontSize: theme.fontSizes.md,

      "&:not(:first-of-type)": {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },
}));

export function NewChatAgents() {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState("");
  const [selectedAgents, setSelectedAgents] = useState<string[]>([]);
  const icon = <IconInfoCircle />;
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch =
    useDispatch<ThunkDispatch<RootState, undefined, AnyAction>>();
  const chat = useSelector((state: RootState) => state.chat);
  const isLoading = useSelector((state: RootState) => state.chat.fetching);
  useEffect(() => {
    if (token) {
      dispatch(fetchAgents(token));
    }
  }, [token, dispatch]);
  const isChatSaved = useSelector((state: RootState) => state.chat.saved);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken); // Update the state with the fetched token
      } catch (error) {
        console.error("Error fetching access token:", error);
        setToken(""); // Handle errors (e.g., by setting token to null)
      }
    };
    fetchToken();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    if (chat.errorCode === 401) {
      dispatch(logout());
      navigate("/");
    }
    if (chat.errorCode) {
      notifications.show({
        title: "Error",
        message: chat.error,
        color: "red",
      });
    }
  }, [chat]);

  useEffect(() => {
    if (isChatSaved) {
      if (Array.isArray(chat.data) && chat.data.length > 0) {
        const id = chat.data[0].id;
        dispatch(resetChatSaved());
        navigate("/chat/" + id);
      } else {
        notifications.show({
          title: "Success",
          message: "Chat saved.",
          color: "green",
        });
      }
      console.log("Chat saved");
    }
  }, [isChatSaved]);
  const handleAgentSelection = (agentId: string) => {
    setSelectedAgents((prevSelectedAgents) => {
      if (prevSelectedAgents.includes(agentId)) {
        return prevSelectedAgents.filter((id) => id !== agentId);
      } else {
        return [...prevSelectedAgents, agentId];
      }
    });
  };
  const createChatHandler = () => {
    console.log("Create chat with these agents:", selectedAgents);
    const newChatData = {
      agent_id: selectedAgents,
      messages: [],
    };

    dispatch(saveChat(newChatData, token));
    // Implement your logic for creating a chat here
  };

  return (
    <Container size="xl">
      <Title color="dimmed" mb={20}>
        Replica Catalogue
      </Title>
      {/* <InputWithButton size="md" /> */}
      <Divider mb={20} />
      {isLoading ? <Loader color="blue" /> : null}
      {chat.agents && chat.agents.length > 0 ? (
        <Grid
          grow
          gutter="md"
          style={{ display: "flex", alignItems: "stretch" }}
        >
          {chat.agents.map((agentObj, index) => {
            return agentObj.plugin_id ? (
              <Col
                span={12}
                xs={6}
                md={4}
                lg={4}
                style={{ display: "flex" }}
                key={index}
              >
                <AgentPreview
                  isSelected={selectedAgents.includes(agentObj.id)}
                  onSelect={() => handleAgentSelection(agentObj.id)}
                  agentId={agentObj.id}
                  fullName={agentObj.fullname}
                  bio={agentObj.bio}
                  yearOfBirth={agentObj.year_of_birth}
                  gender={agentObj.gender}
                  race={agentObj.race}
                  citizenship={agentObj.citizenship}
                  countryOfBirth={agentObj.country_of_birth}
                  countryOfResidence={agentObj.country_of_residence}
                  cityOfResidence={agentObj.city_of_residence}
                  educationLevel={agentObj.education_level}
                  householdIncome={agentObj.household_income}
                  employmentStatus={agentObj.employment_status}
                  martialStatus={agentObj.marital_status}
                  interests={agentObj.interests}
                  additionalInfo={agentObj.additional_info}
                />
              </Col>
            ) : null;
          })}
        </Grid>
      ) : null}

      <Affix position={{ bottom: 20, right: 20 }}>
        <Group spacing="xs">
          {selectedAgents.length > 0 ? (
            <>
              <Text size="sm">
                {selectedAgents.length} Agent
                {selectedAgents.length > 1 ? "s" : ""} Selected
              </Text>
              <Button onClick={createChatHandler}>Create Chat</Button>
            </>
          ) : (
            <>
              <Text size="sm" color="dimmed">
                Select agents to create a chat
              </Text>
              <Button disabled>Create Chat</Button>
            </>
          )}
        </Group>
      </Affix>
    </Container>
  );
}
